<template>
  <div class="index">
    <img src="./assets/agent/qcx-logo.png" class="index_logo" alt="" />
    <el-container>
      <el-aside style="width: 230px">
        <div class="header_logo_b">
          <div class="aside_header_logo">
            <img src="./assets/logo_group.png" alt="" />
          </div>
          <p v-if="internationType" @click="handleCutInter">
            <i class="icon-qiehuan iconfont"></i> 国际版
          </p>
        </div>
        <el-menu
          :default-active="onRoutes"
          class="el-menu-vertical-demo"
          style="height: 75% !important; overflow-y: scroll !important"
          unique-opened
          router
        >
          <!-- 第一层 -->
          <div v-for="(item, index) in filteredSubmenuList" :key="index">
            <template v-if="item.menuList">
              <el-submenu :index="item.path" :key="index">
                <template slot="title">
                  <i
                    class="iconfont"
                    :class="item.icon"
                    style="margin-right: 5px; font-weight: bold"
                  ></i>
                  <span slot="title" style="font-size: 13px; color: #000">{{
                    item.title
                  }}</span>
                </template>

                <!-- 第二层 -->
                <div v-for="(item2, idx2) in item.menuList" :key="idx2">
                  <template v-if="item2.list">
                    <el-submenu :index="item2.path" class="two_have_three_menu">
                      <template slot="title">
                        <span slot="title">{{ item2.title }}</span>
                      </template>

                      <!-- 第三层 -->
                      <div v-for="(item3, idx3) in item2.list" :key="idx3">
                        <template v-if="item3.childList">
                          <el-submenu :index="item3.path">
                            <template slot="title">
                              <span slot="title">{{ item3.title }}</span>
                            </template>

                            <!-- 第四层 -->
                            <div
                              v-for="(item4, idx4) in item3.childList"
                              :key="idx4"
                            >
                              <template v-if="item4.childListP">
                                <el-submenu :index="item4.path">
                                  <template slot="title">
                                    {{ item4.title }}
                                  </template>
                                  <el-menu-item
                                    v-for="(fiveItem, i) in item4.childListP"
                                    :key="i"
                                    :index="fiveItem.path"
                                    >{{ fiveItem.title }}</el-menu-item
                                  >
                                </el-submenu>
                              </template>

                              <!-- 如果第四层没有子菜单 -->
                              <el-menu-item
                                v-else
                                :index="item4.path"
                                style="width: 70%; margin-left: 66px !important"
                                >{{ item4.title }}</el-menu-item
                              >
                            </div>
                          </el-submenu>
                        </template>

                        <!-- 如果第三层没有子菜单 -->
                        <el-menu-item v-else :index="item3.path">{{
                          item3.title
                        }}</el-menu-item>
                      </div>
                    </el-submenu>
                  </template>

                  <!-- 如果第二层没有子菜单 -->
                  <el-menu-item
                    v-else
                    :index="item2.path"
                    class="two_nohave_three"
                  >
                    <span style="margin-left: 10px; font-size: 12px">
                      {{ item2.title }}</span
                    >
                  </el-menu-item>
                </div>
              </el-submenu>
            </template>

            <!-- 如果第一层没有子菜单 -->
            <template v-else>
              <el-menu-item :index="item.index" :key="item.index">
                <i :class="item.icon"></i>
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
          </div>
        </el-menu>
        <div class="aside_footer">
          <img
            src="./assets/agent/head.png"
            class="aside_footer_head_img"
            alt=""
          />
          <p>{{ username }}</p>
          <el-dropdown>
            <img
              src="./assets/agent/dian2.png"
              class="aside_footer_dian_img"
              alt=""
            />
            <el-dropdown-menu slot="dropdown">
              <div @click="outLogin">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </div>

              <div @click="changePass">
                <el-dropdown-item>修改密码</el-dropdown-item>
              </div>

              <div
                @click="changePage"
                v-if="role == '[ROLE_admin, ROLE_normal]'"
              >
                <el-dropdown-item>切换代理后台管理系统</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-dialog title="修改密码" :visible.sync="dialogFormPass">
          <el-form :model="form2">
            <el-form-item label="用户账号" :label-width="formLabelWidth">
              <el-input v-model="form2.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="旧密码" :label-width="formLabelWidth">
              <el-input
                v-model="form2.oldPwd"
                autocomplete="off"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" :label-width="formLabelWidth">
              <el-input
                v-model="form2.newPwd"
                autocomplete="off"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormPass = false">取 消</el-button>
            <el-button type="primary" @click="changePassDialog"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-aside>
      <el-main style="padding: 20px">
        <router-view name="main" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { reqUrlAgent } from "@/api/apiUrl";
import { request } from "@/api/request";

export default {
  name: "Home",
  data() {
    return {
      nowDate: "",
      username: "",
      submenuList: [
        {
          title: "行程上链",
          path: "BoxData",
          icon: "el-icon-box",
          menuList: [
            {
              title: "趣宝盒数据",
              path: "/BoxData",
              list: [
                { title: "盒子状态", path: "/BoxData" },
                { title: "上下级关系", path: "/Superior" },
                { title: "强制绑定/替换盒子ID", path: "/ForceReplaceID" },
                {
                  title: "以旧领新",
                  path: "/TradeIn",
                  childList: [
                    { title: "全部订单", path: "/TradeIn" },
                    { title: "待付款", path: "/TradeInVait" },
                    { title: "待发货", path: "/TradeInVaitDeliver" },
                    { title: "待绑定", path: "/TradeInVaitBind" },
                    { title: "已完成", path: "/TradeInComplete" },
                    { title: "已取消", path: "/TradeInCancel" },
                  ],
                },
                {
                  title: "流量续费",
                  path: "/TrafficRenewal",
                  childList: [
                    // { title: "全部", path: "/TrafficRenewal" },
                    { title: "已过期", path: "/TradeInExpired" },
                    { title: "待续费", path: "/TradeInVaitRenew" },
                    { title: "已续费", path: "/TradeInOkRenew" },
                    { title: "已报废", path: "/TradeInVoidEd" },
                  ],
                },
                { title: "趣宝盒ID库", path: "/BoxIDLibrary" },
              ],
            },
            { title: "强制绑定/替换钱包地址", path: "/ForceReplaceWallet" },
            {
              title: "CPLE每日奖励下发",
              path: "/CPLEReward",
              list: [
                { title: "行程奖励(待发放)", path: "/CPLERewardVait" },
                { title: "行程奖励(历史)", path: "/CPLERewardHistory" },
              ],
            },
            {
              title: "NFT审核",
              path: "/NFTExamine",
              list: [
                { title: "NFT关联审核", path: "/NFTCastingExamine" },
                { title: "NFT铸造审核", path: "/NFTRelationExamine" },
              ],
            },

            {
              title: "代理/运营",
              path: "/AgentIndex",
              list: [
                { title: "用户代理级别查询", path: "/AgentIndex" },
                { title: "市运营", path: "/cityOperation" },
                { title: "省运营", path: "/provinceOperation" },
              ],
            },
          ],
        },
        {
          title: "资产审核",
          path: "/Withdrawal",
          icon: "icon-zichan",
          menuList: [
            {
              title: "余额充值",
              path: "/BalanceVaitList",
              list: [
                { title: "待审核列表", path: "/BalanceVaitList" },
                { title: "审核通过列表", path: "/BalanceSuccessList" },
                { title: "审核拒绝列表", path: "/BalanceFailList" },
              ],
            },
            {
              title: "链上记录",
              path: "/chainRecords",
            },
            {
              title: "支付审核",
              path: "/PaymentExamine",
              list: [
                { title: "待审核列表", path: "/PaymentVaitExamine" },
                { title: "审核通过列表", path: "/PaymentSuccessExamine" },
                { title: "审核拒绝列表", path: "/PaymentFailExamine" },
              ],
            },
            {
              title: "现金提现",
              path: "/CashWithdrawal",
              list: [
                { title: "现金提现列表", path: "/CashWithdrawal" },
                { title: "提现中", path: "/WithdrawalIng" },
                { title: "待提现", path: "/WithdrawalSoon" },
                { title: "提现成功", path: "/WithdrawalSuccess" },
                { title: "提现失败", path: "/WithdrawalFail" },
              ],
            },
            {
              title: "CPLE积分上链审核",
              path: "/CPLEIntegralVait",
              list: [
                { title: "待审核列表", path: "/CPLEIntegralVait" },
                { title: "已审核列表", path: "/CPLEIntegralHistory" },
              ],
            },
            {
              title: "转出额度赠送审核",
              path: "/QuotaGift",
              list: [
                { title: "待审核列表", path: "/QuotaGift" },
                { title: "审核通过", path: "/QuotaGiftSuccess" },
                { title: "审核不通过", path: "/QuotaGiftFail" },
              ],
            },
            {
              title: "火财盒",
              path: "/FireWealthBox",
              list: [
                // { title: "待发放列表", path: "/FireWealthBox" },
                // { title: "历史发放列表", path: "/FireWealthBoxHistory" },
                { title: "待审核列表", path: "/FireWealthBoxVait" },
                { title: "历史审核列表", path: "/FireWealthBoxHistory" },
              ],
            },
          ],
        },
        {
          title: "趣宝商城",
          path: "/Mall",
          icon: "el-icon-goods",
          menuList: [
            {
              title: "订单管理",
              path: "/MallOrderList",
              list: [
                { title: "待付款订单", path: "/MallPayListVait" },
                { title: "待发货订单", path: "/MallPayListVaitDeliver" },
                { title: "已发货订单", path: "/MallPayListDeliver" },
                { title: "已完成订单", path: "/MallPayListComplete" },
                { title: "已取消订单", path: "/MallPayListCancel" },
              ],
            },
            {
              title: "商品上架管理",
              path: "/GoodsShelves",
              list: [{ title: "商品上架", path: "/GoodsShelves" }],
            },
            {
              title: "趣宝盒预售",
              path: "/boxPresellVaitOrder",
              list: [
                { title: "待付款订单", path: "/boxPresellVaitOrder" },
                { title: "待发货订单", path: "/boxPresellVaitDeliveryOrder" },
                { title: "已发货订单", path: "/boxPresellDeliverOrder" },
                { title: "已完成订单", path: "/boxPresellCompleteOrder" },
                { title: "已取消订单", path: "/boxPresellCancelOrder" },
                { title: "退款订单列表", path: "/boxPresellRefundOrderList" },
              ],
            },
          ],
        },
        {
          title: "日常管理",
          path: "/Owner",
          icon: "el-icon-date",
          menuList: [
            {
              title: "车主审核",
              path: "/AuditList",
              list: [
                { title: "审核列表查询", path: "/AuditList" },
                { title: "车主待审核列表", path: "/AuditStatusList" },
                { title: "车主刷脸存档", path: "/AuditFaceScan" },
              ],
            },
            {
              title: "实名认证审核",
              path: "/ArtificialRealName",
              list: [
                // {
                //   title: "人工实名认证",
                //   path: "/ArtificialRealName",
                // },
                { title: "实名认证列表", path: "/RealNameList" },
                { title: "解除实名列表", path: "/RelieveRealName" },
              ],
            },
            // {
            //   title: "banner管理",
            //   path: "/Banner",
            // },
            { title: "版本管理", path: "/Edition" },
            {
              title: "配置管理",
              path: "/Configuration",
              list: [{ title: "系统参数设置", path: "/ConfigurationRate" }],
            },
            { title: "公告管理", path: "/Notice" },
            {
              title: "用户封禁管理",
              path: "/userBanLogin",
              list: [
                { title: "登录封禁", path: "/userBanLogin" },
                { title: "其他封禁", path: "/userBanFeature" },
              ],
            },
          ],
        },
        // {
        //   title: "行程管理",
        //   path: "/TripCity",
        //   icon: "el-icon-truck",
        //   menuList: [
        //     {
        //       title: "手机号",
        //       path: "/TripPhone",
        //     },
        //     {
        //       title: "城市",
        //       path: "/TripCity",
        //     },
        //   ],
        // },
        {
          title: "统计",
          path: "/StatisticsDataBoard",
          icon: "icon-statistics",
          menuList: [
            { title: "大数据看板", path: "/StatisticsDataBoard" },
            { title: "数据汇总", path: "/StatisticsDataCompare" },
            { title: "个人数据比对", path: "/StatisticsDataUserCompare" },
            { title: "单日数据", path: "/StatisticsOneDayData" },
          ],
        },
        {
          title: "行世界",
          path: "/qcxWorld",
          icon: "icon-world",
          menuList: [
            { title: "权限管理", path: "/worldAuthority" },
            { title: "城市管理", path: "/cityManagement" },
            { title: "线路管理", path: "/worldLine" },
            {
              title: "用户质押管理",
              path: "/worldUserPledgeNotLock",
              list: [
                { title: "手动添加质押", path: "/worldUserPledgeCreate" },
                { title: "未锁仓", path: "/worldUserPledgeNotLock" },
                { title: "锁仓中", path: "/worldUserPledgeLocking" },
                { title: "锁仓结束，待申请", path: "/worldUserPledgeFinish" },
                { title: "已申请，待解押", path: "/worldUserPledgeVaitAudit" },
                {
                  title: "已申请，审核拒绝",
                  path: "/worldUserPledgeFailAudit",
                },
              ],
            },
            {
              title: "活动管理",
              path: "/worldActivityList",
              list: [
                { title: "发布活动", path: "/worldActivityList" },
                { title: "订单管理", path: "/worldActivityOrder" },
              ],
            },
            {
              title: "酒店管理",
              path: "/worldHotelList",
              list: [
                { title: "发布酒店", path: "/worldHotelList" },
                { title: "订单管理", path: "/worldHotelOrderDataList" },
              ],
            },
            {
              title: "游玩项目",
              path: "/worldPlayList",
              list: [
                { title: "发布游玩项目", path: "/worldPlayList" },
                { title: "订单管理", path: "/worldPlayOrderList" },
              ],
            },
          ],
        },
      ],

      submenuList2: [
        {
          title: "行程上链",
          path: "BoxData",
          icon: "el-icon-box",
          menuList: [
            {
              title: "趣宝盒数据",
              path: "/BoxData",
              list: [
                { title: "盒子状态", path: "/BoxData" },
                { title: "上下级关系", path: "/Superior" },
                { title: "强制绑定/替换盒子ID", path: "/ForceReplaceID" },
                {
                  title: "以旧领新",
                  path: "/TradeIn",
                  childList: [
                    { title: "全部订单", path: "/TradeIn" },
                    { title: "待付款", path: "/TradeInVait" },
                    { title: "待发货", path: "/TradeInVaitDeliver" },
                    { title: "待绑定", path: "/TradeInVaitBind" },
                    { title: "已完成", path: "/TradeInComplete" },
                    { title: "已取消", path: "/TradeInCancel" },
                  ],
                },
                {
                  title: "流量续费",
                  path: "/TrafficRenewal",
                  childList: [
                    { title: "已过期", path: "/TradeInExpired" },
                    { title: "待续费", path: "/TradeInVaitRenew" },
                    { title: "已续费", path: "/TradeInOkRenew" },
                    { title: "已报废", path: "/TradeInVoidEd" },
                  ],
                },
                { title: "趣宝盒ID库", path: "/BoxIDLibrary" },
              ],
            },
            { title: "强制绑定/替换钱包地址", path: "/ForceReplaceWallet" },
            {
              title: "CPLE质押",
              path: "/CPLEpledge",
              list: [
                { title: "添加质押", path: "/AddCPLEpledge" },
                { title: "质押中", path: "/CPLEpledgeIng" },
                { title: "解押中", path: "/CPLEpledgeApplyIng" },
                { title: "已解押", path: "/CPLEpledgeEd" },
              ],
            },
            {
              title: "CPLE每日奖励下发",
              path: "/CPLEReward",
              list: [
                { title: "行程奖励(待发放)", path: "/CPLERewardVait" },
                { title: "行程奖励(历史)", path: "/CPLERewardHistory" },
              ],
            },
            {
              title: "NFT审核",
              path: "/NFTExamine",
              list: [
                { title: "NFT关联审核", path: "/NFTCastingExamine" },
                { title: "NFT铸造审核", path: "/NFTRelationExamine" },
              ],
            },

            {
              title: "代理/运营",
              path: "/AgentIndex",
              list: [
                { title: "用户代理级别查询", path: "/AgentIndex" },
                { title: "市运营", path: "/cityOperation" },
                { title: "省运营", path: "/provinceOperation" },
              ],
            },
          ],
        },
        {
          title: "资产审核",
          path: "/Withdrawal",
          icon: "icon-zichan",
          menuList: [
            {
              title: "余额充值",
              path: "/BalanceVaitList",
              list: [
                { title: "待审核列表", path: "/BalanceVaitList" },
                { title: "审核通过列表", path: "/BalanceSuccessList" },
                { title: "审核拒绝列表", path: "/BalanceFailList" },
              ],
            },
            {
              title: "链上记录",
              path: "/chainRecords",
            },
            {
              title: "支付审核",
              path: "/PaymentExamine",
              list: [
                { title: "待审核列表", path: "/PaymentVaitExamine" },
                { title: "审核通过列表", path: "/PaymentSuccessExamine" },
                { title: "审核拒绝列表", path: "/PaymentFailExamine" },
              ],
            },
            {
              title: "现金提现",
              path: "/CashWithdrawal",
              list: [
                { title: "现金提现列表", path: "/CashWithdrawal" },
                { title: "提现中", path: "/WithdrawalIng" },
                { title: "待提现", path: "/WithdrawalSoon" },
                { title: "提现成功", path: "/WithdrawalSuccess" },
                { title: "提现失败", path: "/WithdrawalFail" },
              ],
            },
            {
              title: "CPLE积分上链审核",
              path: "/CPLEIntegralVait",
              list: [
                { title: "待审核列表", path: "/CPLEIntegralVait" },
                { title: "已审核列表", path: "/CPLEIntegralHistory" },
              ],
            },
            {
              title: "转出额度赠送审核",
              path: "/QuotaGift",
              list: [
                { title: "待审核列表", path: "/QuotaGift" },
                { title: "审核通过", path: "/QuotaGiftSuccess" },
                { title: "审核不通过", path: "/QuotaGiftFail" },
              ],
            },
            {
              title: "火财盒",
              path: "/FireWealthBox",
              list: [
                { title: "待审核列表", path: "/FireWealthBoxVait" },
                { title: "历史审核列表", path: "/FireWealthBoxHistory" },
              ],
            },
          ],
        },
        {
          title: "趣宝商城",
          path: "/Mall",
          icon: "el-icon-goods",
          menuList: [
            {
              title: "订单管理",
              path: "/MallOrderList",
              list: [
                { title: "待付款订单", path: "/MallPayListVait" },
                { title: "待发货订单", path: "/MallPayListVaitDeliver" },
                { title: "已发货订单", path: "/MallPayListDeliver" },
                { title: "已完成订单", path: "/MallPayListComplete" },
                { title: "已取消订单", path: "/MallPayListCancel" },
              ],
            },
            {
              title: "商品上架管理",
              path: "/GoodsShelves",
              list: [{ title: "商品上架", path: "/GoodsShelves" }],
            },
            {
              title: "趣宝盒预售",
              path: "/boxPresellVaitOrder",
              list: [
                { title: "待付款订单", path: "/boxPresellVaitOrder" },
                { title: "待发货订单", path: "/boxPresellVaitDeliveryOrder" },
                { title: "已发货订单", path: "/boxPresellDeliverOrder" },
                { title: "已完成订单", path: "/boxPresellCompleteOrder" },
                { title: "已取消订单", path: "/boxPresellCancelOrder" },
                { title: "退款订单列表", path: "/boxPresellRefundOrderList" },
              ],
            },
          ],
        },
        {
          title: "日常管理",
          path: "/Owner",
          icon: "el-icon-date",
          menuList: [
            {
              title: "车主审核",
              path: "/AuditList",
              list: [
                { title: "审核列表查询", path: "/AuditList" },
                { title: "车主待审核列表", path: "/AuditStatusList" },
                { title: "车主刷脸存档", path: "/AuditFaceScan" },
              ],
            },
            {
              title: "实名认证审核",
              path: "/ArtificialRealName",
              list: [
                { title: "实名认证列表", path: "/RealNameList" },
                { title: "解除实名列表", path: "/RelieveRealName" },
              ],
            },

            { title: "版本管理", path: "/Edition" },
            {
              title: "配置管理",
              path: "/Configuration",
              list: [{ title: "系统参数设置", path: "/ConfigurationRate" }],
            },
            { title: "公告管理", path: "/Notice" },
            {
              title: "用户封禁管理",
              path: "/userBanLogin",
              list: [
                { title: "登录封禁", path: "/userBanLogin" },
                { title: "其他封禁", path: "/userBanFeature" },
              ],
            },
          ],
        },

        {
          title: "统计",
          path: "/StatisticsDataBoard",
          icon: "icon-statistics",
          menuList: [
            { title: "大数据看板", path: "/StatisticsDataBoard" },
            { title: "数据汇总", path: "/StatisticsDataCompare" },
            { title: "个人数据比对", path: "/StatisticsDataUserCompare" },
            { title: "单日数据", path: "/StatisticsOneDayData" },
          ],
        },
        {
          title: "行世界",
          path: "/qcxWorld",
          icon: "icon-world",
          menuList: [
            { title: "权限管理", path: "/worldAuthority" },
            { title: "城市管理", path: "/cityManagement" },
            { title: "线路管理", path: "/worldLine" },
            {
              title: "用户质押管理",
              path: "/worldUserPledgeNotLock",
              list: [
                { title: "手动添加质押", path: "/worldUserPledgeCreate" },
                { title: "未锁仓", path: "/worldUserPledgeNotLock" },
                { title: "锁仓中", path: "/worldUserPledgeLocking" },
                { title: "锁仓结束，待申请", path: "/worldUserPledgeFinish" },
                { title: "已申请，待解押", path: "/worldUserPledgeVaitAudit" },
                {
                  title: "已申请，审核拒绝",
                  path: "/worldUserPledgeFailAudit",
                },
              ],
            },
            {
              title: "活动管理",
              path: "/worldActivityList",
              list: [
                { title: "发布活动", path: "/worldActivityList" },
                { title: "订单管理", path: "/worldActivityOrder" },
              ],
            },
            {
              title: "酒店管理",
              path: "/worldHotelList",
              list: [
                { title: "发布酒店", path: "/worldHotelList" },
                { title: "订单管理", path: "/worldHotelOrderDataList" },
              ],
            },
            {
              title: "游玩项目",
              path: "/worldPlayList",
              list: [
                { title: "发布游玩项目", path: "/worldPlayList" },
                { title: "订单管理", path: "/worldPlayOrderList" },
              ],
            },
          ],
        },
      ],

      dialogFormVisible: false,
      form: {
        password: "",
      },

      role: "",
      dialogFormPass: false,
      formLabelWidth: "120px",
      form2: {
        username: localStorage.getItem("userName"),
        oldPwd: "",
        newPwd: "",
      },

      internationType: false, // 判断是否可切换国际版

      userRole: "",
    };
  },

  methods: {
    goCon(conE) {
      if (this.$route.path === conE) {
        return;
      } else {
        this.$router.push(conE);
      }
    },

    //获取当前时间
    currentTime() {
      setInterval(this.getCurrentTime, 500);
    },
    getCurrentTime() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      _this.gettime =
        yy + "年" + mm + "月" + dd + "日 " + hh + ":" + mf + ":" + ss;
      this.nowDate = _this.gettime;
    },

    // 退出登录
    outLogin() {
      this.$confirm("您确定要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出登录成功",
          });
          this.$router.push("/");
          localStorage.removeItem("userName");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出登录",
          });
        });
    },

    changePage() {
      this.$confirm("您是否要切入“代理后台管理系统”", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "切换成功",
          });
          this.$router.push("/TodayData");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    changePass() {
      this.dialogFormPass = true;
    },

    changePassDialog() {
      const opt = {
        url: reqUrlAgent.modifyPassUrl,
        method: "POST",
        params: JSON.stringify({
          username: this.form2.username,
          oldPwd: this.form2.oldPwd,
          newPwd: this.form2.newPwd,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            console.log(res.data);
            this.$message({
              type: "success",
              message: "修改成功，请使用新密码重新登录",
            });
            const opt = {
              url: reqUrlAgent.outLoginUrl,
              method: "POST",
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$router.push("/");
                  localStorage.clear();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 切换国际版
    handleCutInter() {
      this.$router.push("/IndexInternation");
    },
  },

  computed: {
    // 动态返回对应的路由path
    onRoutes() {
      return this.$route.path;
    },

    filteredSubmenuList() {
      // 根据用户角色过滤菜单项
      if (this.username !== "13436969806") {
        return this.submenuList;
      } else {
        return this.submenuList2;
      }
    },
  },

  mounted() {
    this.currentTime();
    this.username = localStorage.getItem("userName");
    this.role = localStorage.getItem("ROLE");

    var strAdmin = this.role.includes("ROLE_admin");
    if (strAdmin) {
      this.internationType = true;
    }
  },

  beforeDestroy() {
    if (this.getCurrentTime) {
      clearInterval(this.getCurrentTime); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.index {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ccd5fc, #fff);
  background-size: 100% 100vh;
  position: relative;

  .index_logo {
    width: 240px;
    height: 160px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .el-aside {
    height: 100vh;
    border-right: 1px #ccc solid;
    color: #333;
    text-align: center;
    position: relative;

    .header_logo_b {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .aside_header_logo {
        height: 100%;
        display: flex;
        align-items: center;

        img {
          width: 100px;
          height: 32px;
          margin-left: 30px;
        }
      }

      p {
        text-align: right;
        margin-right: 40px;
        color: #4f4f4f;
        cursor: pointer;
      }
    }

    .el-submenu__title {
      text-align: left;
      font-size: 12px;
      span {
        color: #000000;
        opacity: 0.65;
      }
    }
    ::v-deep .el-menu {
      box-shadow: 10px 0px 0px 0px #ccc;
      border-right: none !important;
      background-color: unset !important;

      .el-menu-item {
        text-align: left;
        color: #000000;
        opacity: 0.65;
        font-size: 12px;
        min-width: 0;
        padding: 0;
      }

      .el-menu-item.is-active {
        background: #6376ff;
        border-radius: 30px;
        color: #fff !important;
        font-weight: 500;
        font-size: 12px;
      }

      .el-menu-item:hover {
        border-right: none;
        color: #0021ff;
      }

      .el-submenu__title:hover,
      .el-submenu__title:focus {
        border-radius: 34px !important;
        outline: 0;
      }

      .two_have_three_menu {
        .el-menu-item {
          border-radius: 30px !important;
          margin-left: 44px !important;
          padding-left: 15px !important;
          color: #000 !important;
          font-size: 12px !important;
        }

        .el-menu-item.is-active {
          border-radius: 30px !important;
          margin-left: 44px !important;
          padding-left: 15px !important;
          color: #fff !important;
          font-size: 12px !important;
        }
      }
    }

    .two_nohave_three {
      width: 85%;
      margin-left: 22px;
      padding-left: 8px !important;
      color: #000 !important;
      font-size: 12px !important;
      border-radius: 30px !important;
    }

    .four_nohave {
      padding-left: 100px !important;
    }
  }
}
</style>
