<template>
  <div class="login">
    <img src="@/assets/agent/qcx-logo.png" class="index_logo" alt="" />

    <div class="login_form">
      <div class="login_form_bg">
        <div class="login_form_bg_img">
          <img src="@/assets/agent/logo.png" class="logo_img" alt="" />
          <img src="@/assets/agent/qcx-text.png" class="qcx_text_img" alt="" />
        </div>

        <!-- 密码登录 -->
        <el-form
          :rules="rules"
          ref="ruleForm"
          :model="ruleForm"
          v-if="loginStatus == 0"
        >
          <el-form-item class="form_phone" prop="phone">
            <div class="form_phone_code">
              <el-select
                v-model="valueArea"
                filterable
                placeholder="请选择"
                style="width: 120px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :value="item.tel"
                >
                  <div style="display: flex; align-items: center">
                    {{ item.tel }} {{ item.name }}
                  </div>
                </el-option>
              </el-select>
            </div>
            <el-input
              placeholder="请输入手机号"
              v-model="ruleForm.phone"
              autocomplete="off"
              oninput="value=value.replace(/^0|[^\d]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item class="form_phone" prop="pass">
            <el-input
              placeholder="请输入密码"
              :type="flagType"
              v-model="ruleForm.pass"
              autocomplete="new-password"
              style="padding-left: 16px"
            ></el-input>
            <div class="form_pass_code">
              <img
                :src="
                  this.flag
                    ? require('@/assets/agent/eye-z.png')
                    : require('@/assets/agent/eye.png')
                "
                @click="getFlagEye"
                alt=""
              />
            </div>
          </el-form-item>
        </el-form>

        <!-- 验证码登录 -->
        <el-form
          :rules="Coderules"
          ref="Coderules"
          :model="ruleForm"
          v-if="loginStatus == 1"
        >
          <el-form-item prop="phone">
            <div class="form_phone_code">
              <span>+86</span><img src="@/assets/agent/right.png" alt="" />
            </div>
            <el-input
              placeholder="请输入手机号"
              v-model="ruleForm.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              placeholder="请输入验证码"
              v-model="ruleForm.code"
              autocomplete="new-password"
              style="padding-left: 16px"
            ></el-input>
            <el-button
              type="text"
              class="form_pass_code_send"
              v-show="timerTf"
              @click="handleSendCode('Coderules')"
            >
              发送验证码
            </el-button>
            <div
              class="form_pass_code_send"
              v-show="!timerTf"
              style="color: #7d828c"
            >
              {{ countDownNum }} s
            </div>
          </el-form-item>
        </el-form>

        <div class="login_footer">
          <el-button
            @click="submitForm('Coderules', 'code')"
            class="now_login_btn"
            v-if="loginStatus == 1"
            >立即登录</el-button
          >
          <el-button
            @click="submitForm('ruleForm', 'pass')"
            class="now_login_btn"
            v-if="loginStatus == 0"
            >立即登录</el-button
          >
          <div class="code_pass">
            <el-button v-if="loginStatus == 0" @click="loginStatus = 1"
              >验证码登录</el-button
            >
            <el-button v-if="loginStatus == 1" @click="loginStatus = 0"
              >密码登录</el-button
            >
            <span></span>
            <el-button :plain="true" @click="retrievePass">找回密码</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqUrlAgent } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("号码不能为空"));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error("手机号格式错误"));
        } else {
          callback();
        }
      }, 100);
    };
    var validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };

    var validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("验证码不能为空"));
      } else {
        callback();
      }
    };

    return {
      loginStatus: 0, // 0立即登录，1验证码登录，2找回密码

      timerTf: true,
      countDownNum: 60,

      ruleForm: {
        phone: "",
        pass: "",
        code: "",
      },
      flag: false,
      flagType: "password",
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
      Coderules: {
        // phone: [{ validator: validatePhone, trigger: "blur" }],
      },

      valueArea: "+86",
      options: [],
    };
  },

  mounted() {
    this.options = areaCodeData;
  },

  methods: {
    getFlagEye() {
      this.flag = !this.flag;
      this.flagType = this.flag ? "text" : "password";
    },

    submitForm(formName, status) {
      this.$refs[formName].validate((valid) => {
        if (status == "code") {
          if (valid) {
            showLoading();
            const opt = {
              url: reqUrlAgent.agentLoginCodeUrl,
              method: "POST",
              params: JSON.stringify({
                phone: this.ruleForm.phone,
                area: this.valueArea,
                code: this.ruleForm.code,
              }),
              resFunc: (res) => {
                console.log(res);
                if (res.data.code == 0) {
                  localStorage.setItem("userName", this.ruleForm.phone);
                  localStorage.setItem(
                    "resHeadersAuthorization",
                    res.headers.authorization
                  );
                  if (res.data.data.city) {
                    localStorage.setItem("city", res.data.data.city);
                  }
                  localStorage.setItem("country", res.data.data.country);
                  localStorage.setItem("province", res.data.data.province);
                  localStorage.setItem("phoneArea", res.data.data.phoneArea);
                  localStorage.setItem("ROLE", res.data.data.auth);

                  var strAdmin = res.data.data.auth.includes("ROLE_admin");
                  var strNormal = res.data.data.auth.includes("ROLE_normal");
                  var strPhoneArea = res.data.data.phoneArea != "+86";

                  if (strPhoneArea) {
                    this.$router.push("/internation");
                  }

                  if (strAdmin && !strNormal) {
                    this.$router.push("/index");
                  } else if (!strAdmin && strNormal) {
                    this.$router.push("/TodayData");
                  } else if (strAdmin && strNormal) {
                    this.$confirm("请选择您将要进入的后台管理系统", "提示", {
                      confirmButtonText: "代理后台管理系统",
                      cancelButtonText: "审核后台管理系统",
                    })
                      .then(() => {
                        this.$router.push("/TodayData");
                      })
                      .catch(() => {
                        this.$router.push("/index");
                      });
                  }

                  hideLoading();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });

                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          } else {
            return false;
          }
        } else if (status == "pass") {
          if (valid) {
            showLoading();
            const opt = {
              url: reqUrlAgent.agentLoginPassUrl,
              method: "POST",
              params: JSON.stringify({
                name: this.ruleForm.phone,
                pass: this.ruleForm.pass,
                phoneArea: this.valueArea,
              }),
              resFunc: (res) => {
                console.log(res.data.data);
                if (res.data.code == 0) {
                  localStorage.setItem("userName", this.ruleForm.phone);
                  localStorage.setItem(
                    "resHeadersAuthorization",
                    res.headers.authorization
                  );
                  if (res.data.data.city) {
                    localStorage.setItem("city", res.data.data.city);
                  }
                  localStorage.setItem("country", res.data.data.country);
                  localStorage.setItem("province", res.data.data.province);
                  localStorage.setItem("phoneArea", res.data.data.phoneArea);
                  localStorage.setItem("ROLE", res.data.data.auth);

                  var strAdmin = res.data.data.auth.includes("ROLE_admin");
                  var strNormal = res.data.data.auth.includes("ROLE_normal");

                  if (strAdmin && !strNormal) {
                    this.$router.push("/index");
                  } else if (!strAdmin && strNormal) {
                    this.$router.push("/TodayData");
                  } else if (strAdmin && strNormal) {
                    this.$confirm("请选择您将要进入的后台管理系统", "提示", {
                      confirmButtonText: "代理后台管理系统",
                      cancelButtonText: "审核后台管理系统",
                    })
                      .then(() => {
                        this.$router.push("/TodayData");
                      })
                      .catch(() => {
                        this.$router.push("/index");
                      });
                  }

                  hideLoading();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
                hideLoading();
              },
            };
            request(opt);
          } else {
            return false;
          }
        }
      });
    },

    handleSendCode(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          showLoading();
          const opt = {
            url: reqUrlAgent.sendCodeUrl,
            method: "POST",
            params: JSON.stringify({
              phone: this.ruleForm.phone,
              phoneArea: this.valueArea,
              scene: 6,
            }),
            resFunc: (res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message({
                  message: "发送成功，请注意查收短信",
                  type: "success",
                });
                this.countDownNum = 60;
                this.timerTf = false;
                this.timer = setInterval(() => {
                  if (this.countDownNum > 0 && this.countDownNum <= 60) {
                    this.countDownNum--;
                  } else {
                    this.timerTf = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
                hideLoading();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        }
      });
    },

    retrievePass() {
      const url = require("@/assets/agent/msg_warning.png");
      this.$message({
        iconClass: " ",
        dangerouslyUseHTMLString: true,
        message: `<img src=${url} class='msg_warning_img' /><div class='message_warn'><h3>找回密码</h3><p class='message_txt'>请在趣出行app中完成操作<br />路径:我的-设置-重置登录密码</p></div>`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-message__content {
  display: flex;
}

.login {
  width: 100%;
  height: 100vh;
  background: url(../../../assets/agent/background.png) no-repeat;
  background-size: 100% 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .index_logo {
    width: 240px;
    height: 160px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .login_form {
    width: 360px;
    height: 420px;
    background: #fff;
    border-radius: 16px;

    .login_form_bg {
      width: 360px;
      background: url(../../../assets/agent/login-bg.png) no-repeat;
      background-size: 100% 232px;
      overflow: hidden;

      .login_form_bg_img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .logo_img {
          width: 30px;
          height: 30px;
          margin-right: 7px;
        }

        .qcx_text_img {
          width: 85px;
          height: 27px;
        }
      }

      ::v-deep .el-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 48px;

        .el-form-item__error {
          padding-top: 7px;
        }

        .el-form-item__content {
          display: flex;
          align-items: center;
          width: 280px;
          border: 1px #dfe1e5 solid;
          border-radius: 12px;
          background: #fff;
          // overflow: hidden;

          .form_phone_code {
            .el-input__inner {
              margin-left: 6px;
              font-size: 15px;
              width: 70px;
              text-align: center;
              padding-right: 25px;
              color: #7d828c;
            }

            .el-select .el-input .el-select__caret {
              font-weight: bold;
              color: #7d828c;
            }
          }

          .form_phone_code,
          .form_pass_code {
            display: flex;
            align-items: center;
            width: 70px;
            height: 48px;
            background: #fff;
            border-radius: 20px;

            span {
              padding: 0 0 0 16px;
              font-size: 16px;
              color: #7d828c;
            }

            img {
              width: 18px;
              height: 18px;
              margin-right: 5px;
            }
          }

          .form_pass_code {
            width: 35px;
          }

          .form_pass_code_send {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f2f5fa;
            border-radius: 8px;
            width: 150px;
            margin-right: 5px;
            font-size: 13px;
            color: #5778ff;
          }

          .el-input__inner {
            height: 48px;
            border: none;
            padding: 0;
            border-top-right-radius: 11px;
            border-bottom-right-radius: 11px;

            &::placeholder {
              color: #aeb4bf;
              font-size: 16px;
            }

            &:-webkit-autofill {
              background: transparent !important;
              transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
            }

            &:-internal-autofill-selected {
              background: transparent;
              -webkit-text-fill-color: #333 !important;
              transition: background-color 5000s ease-in-out 0s !important;
            }
          }
        }
      }

      .login_footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 38px;

        .now_login_btn {
          width: 240px;
          height: 48px;
          background: #5778ff;
          border-radius: 80px;
          color: #fff;
          font-size: 16px;
        }

        .code_pass {
          display: flex;
          align-items: center;
          margin-top: 24px;
          font-weight: 400;
          font-size: 14px;
          color: #202226;

          span {
            display: block;
            width: 2px;
            height: 12px;
            background: #ebedf2;
            border-radius: 5px;
            margin: 0 24px;
          }

          .el-button {
            border: none;
            background: #fff;
          }
        }
      }
    }
  }
}
</style>

<style lang="sass">
.el-message
  background-color: #e6f7ff !important
  border: 1px solid #91d5ff !important
  border-radius: 16px !important

  .el-message__content
    display: flex

    .msg_warning_img
      width: 25px
      height: 25px
      margin-top: -4px
      margin-right: 8px

    .message_warn
      h3
        font-weight: 500
        font-size: 16px
        color: rgba(0, 0, 0, 0.85)

      .message_txt
        font-weight: 400
        font-size: 14px
        line-height: 20px
        color: rgba(0, 0, 0, 0.85)
        margin-top: 7px
</style>
