<template>
  <div class="index">
    <img src="./assets/agent/qcx-logo.png" class="index_logo" alt="" />
    <el-container>
      <el-aside style="width: 230px">
        <div class="header_logo_b">
          <div class="aside_header_logo">
            <img src="./assets/logo_group.png" alt="" />
          </div>
          <p v-if="internationType" @click="handleCutInter">
            <i class="icon-qiehuan iconfont"></i> 国内版
          </p>
        </div>
        <el-menu
          :default-active="onRoutes"
          class="el-menu-vertical-demo"
          style="height: 75% !important; overflow-y: scroll !important"
        >
          <div v-for="(item, index) in submenuList" :key="index">
            <!-- 一级菜单（没有任何子级菜单）-->
            <el-menu-item
              @click="goCon(item.path)"
              :index="item.path"
              v-if="!item.menuList"
              style="color: #303133"
            >
              <i
                :class="item.icon"
                class="iconfont"
                style="margin-right: 5px; font-weight: bold"
              ></i>
              {{ item.title }}</el-menu-item
            >
            <!-- 一级菜单（有子级菜单）-->
            <el-submenu :index="item.path" v-else>
              <template slot="title"
                ><i
                  :class="item.icon"
                  class="iconfont"
                  style="margin-right: 5px; font-weight: bold"
                ></i>
                <span style="font-size: 13px; color: #000">
                  {{ item.title }}
                </span></template
              >

              <!-- 遍历二级菜单容器 -->
              <div v-for="(i, index) in item.menuList" :key="index">
                <!-- 判断二级菜单（没有三级菜单）-->
                <el-menu-item
                  @click="goCon(i.path)"
                  :index="i.path"
                  v-if="!i.list"
                >
                  <i style="margin-right: 7px; font-weight: bold"></i>
                  <span style="margin-left: 10px; font-size: 13px">{{
                    i.title
                  }}</span></el-menu-item
                >

                <!-- 判断二级菜单（有三级菜单）-->
                <el-submenu
                  :index="i.path"
                  v-if="i.list"
                  style="padding-left: 15px !important"
                  class="two_have_three_menu"
                >
                  <template slot="title"
                    ><span style="font-size: 13px; color: #000">{{
                      i.title
                    }}</span></template
                  >
                  <el-menu-item
                    :index="j.path"
                    v-for="(j, index) in i.list"
                    :key="index"
                    @click="goCon(j.path)"
                    style="padding-left: 15px"
                  >
                    <i
                      :class="j.icon"
                      class="iconfont"
                      style="margin-right: 7px; font-weight: bold"
                    ></i>
                    {{ j.title }}
                  </el-menu-item>
                </el-submenu>
              </div>
            </el-submenu>
          </div>
        </el-menu>
        <div class="aside_footer">
          <img
            src="./assets/agent/head.png"
            class="aside_footer_head_img"
            alt=""
          />
          <p>{{ username }}</p>
          <el-dropdown>
            <img
              src="./assets/agent/dian2.png"
              class="aside_footer_dian_img"
              alt=""
            />
            <el-dropdown-menu slot="dropdown">
              <div @click="outLogin">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </div>

              <div @click="changePass">
                <el-dropdown-item>修改密码</el-dropdown-item>
              </div>

              <div
                @click="changePage"
                v-if="role == '[ROLE_admin, ROLE_normal]'"
              >
                <el-dropdown-item>切换代理后台管理系统</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-dialog title="修改密码" :visible.sync="dialogFormPass">
          <el-form :model="form2">
            <el-form-item label="用户账号" :label-width="formLabelWidth">
              <el-input v-model="form2.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="旧密码" :label-width="formLabelWidth">
              <el-input
                v-model="form2.oldPwd"
                autocomplete="off"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" :label-width="formLabelWidth">
              <el-input
                v-model="form2.newPwd"
                autocomplete="off"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormPass = false">取 消</el-button>
            <el-button type="primary" @click="changePassDialog"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-aside>
      <el-main style="padding: 20px">
        <router-view name="main" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { reqUrlAgent } from "@/api/apiUrl";
import { request } from "@/api/request";
export default {
  name: "Home",
  data() {
    return {
      nowDate: "",
      username: "",
      submenuList: [
        {
          title: "日常管理",
          path: "InternationRealName",
          icon: "el-icon-date",
          menuList: [
            {
              title: "实名认证",
              path: "/InternationRealName",
              icon: "el-icon-date",
              list: [
                {
                  title: "待审核列表",
                  path: "/Internation/RealName",
                },
                {
                  title: "审核通过列表",
                  path: "/Internation/RealName/examinePass",
                },
                {
                  title: "审核拒绝列表",
                  path: "/Internation/RealName/examineRefuse",
                },
              ],
            },
            {
              title: "车主认证",
              path: "/InternationOwner",
              icon: "el-icon-date",
              list: [
                {
                  title: "待审核列表",
                  path: "/Internation/Owner",
                },
                {
                  title: "审核通过列表",
                  path: "/Internation/Owner/examinePass",
                },
                {
                  title: "审核拒绝列表",
                  path: "/Internation/Owner/examineRefuse",
                },
              ],
            },
          ],
        },
      ],
      dialogFormVisible: false,
      form: {
        password: "",
      },

      role: "",
      dialogFormPass: false,
      formLabelWidth: "120px",
      form2: {
        username: localStorage.getItem("userName"),
        oldPwd: "",
        newPwd: "",
      },

      internationType: false, // 判断是否可切换国内版
    };
  },

  methods: {
    goCon(conE) {
      if (this.$route.path === conE) {
        return;
      } else {
        this.$router.push(conE);
      }
    },

    //获取当前时间
    currentTime() {
      setInterval(this.getCurrentTime, 500);
    },
    getCurrentTime() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      _this.gettime =
        yy + "年" + mm + "月" + dd + "日 " + hh + ":" + mf + ":" + ss;
      this.nowDate = _this.gettime;
    },

    // 退出登录
    outLogin() {
      this.$confirm("您确定要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出登录成功",
          });
          this.$router.push("/");
          localStorage.removeItem("userName");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出登录",
          });
        });
    },

    changePage() {
      this.$confirm("您是否要切入“代理后台管理系统”", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "切换成功",
          });
          this.$router.push("/TodayData");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    changePass() {
      this.dialogFormPass = true;
    },

    changePassDialog() {
      const opt = {
        url: reqUrlAgent.modifyPassUrl,
        method: "POST",
        params: JSON.stringify({
          username: this.form2.username,
          oldPwd: this.form2.oldPwd,
          newPwd: this.form2.newPwd,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            console.log(res.data);
            this.$message({
              type: "success",
              message: "修改成功，请使用新密码重新登录",
            });
            const opt = {
              url: reqUrlAgent.outLoginUrl,
              method: "POST",
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$router.push("/");
                  localStorage.clear();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 切换国内版
    handleCutInter() {
      this.$router.push("/index");
    },
  },

  computed: {
    // 动态返回对应的路由path
    onRoutes() {
      return this.$route.path;
    },
  },

  mounted() {
    this.currentTime();
    this.username = localStorage.getItem("userName");
    this.role = localStorage.getItem("ROLE");

    var strAdmin = this.role.includes("ROLE_admin");
    if (strAdmin) {
      this.internationType = true;
    }
  },

  beforeDestroy() {
    if (this.getCurrentTime) {
      clearInterval(this.getCurrentTime); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

.index {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ccd5fc, #fff);
  background-size: 100% 100vh;
  position: relative;

  .index_logo {
    width: 240px;
    height: 160px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .el-aside {
    height: 100vh;
    border-right: 1px #ccc solid;
    color: #333;
    text-align: center;
    position: relative;

    .aside_header_logo {
      height: 15%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 32px;
        margin-left: 30px;
      }
    }

    .el-submenu__title {
      text-align: left;
      font-size: 12px;
      span {
        color: #000000;
        opacity: 0.65;
      }
    }
    .el-menu {
      box-shadow: 10px 0px 0px 0px #ccc;
      border-right: none !important;
      background-color: unset !important;

      .el-menu-item {
        text-align: left;
        color: #000000;
        opacity: 0.65;
        font-size: 12px;
        min-width: 0;
        padding: 0;
      }

      .el-menu-item.is-active {
        background: #6376ff;
        border-radius: 30px;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
      }

      .el-menu-item:hover {
        border-right: none;
        color: #0021ff;
      }

      .el-submenu__title:hover,
      .el-submenu__title:focus {
        border-radius: 34px !important;
        outline: 0;
      }

      .two_have_three_menu {
        .el-menu-item {
          border-radius: 30px !important;
          margin-left: 30px !important;
        }

        .el-menu-item.is-active {
          border-radius: 30px !important;
          margin-left: 30px !important;
        }
      }
    }
  }
}
</style>
