import Vue from "vue";
import VueRouter from "vue-router";
import JsonExcel from "vue-json-excel";
import i18n from "./lang/i18n";

import App from "./App.vue";
import ElementUI from "element-ui";
import router from "./router";
import store from "./store";

import BaiduMap from "vue-baidu-map";

import { preventReClick } from "./util/repeatClick";
import relationGraph from "relation-graph";
Vue.use(relationGraph);

Vue.use(BaiduMap, {
  ak: "WncTOouugkSiSX4ZyUkjkhOrfBBYIkLj", //百度地图密钥
});

Vue.component("downloadExcel", JsonExcel);

import "element-ui/lib/theme-chalk/index.css";

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueRouter);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
