<template>
  <div class="login">
    <img src="@/assets/qcx-logo.png" class="login_logo_head" alt="" />

    <div class="login_con">
      <img src="@/assets/logo_group.png" alt="" />
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass"></el-input>
        </el-form-item>
        <el-button
          type="primary"
          round
          @click="submitForm('ruleForm')"
          @keyup.enter.native="toLogin()"
          >登 录</el-button
        >
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };

    return {
      ruleForm: {
        pass: "",
        username: "",
      },
      rules: {
        username: [{ required: true, message: "用户名不能为空" }],
        pass: [
          { required: true, validator: validatePass, trigger: "blur" },
          { min: 1, max: 18, message: "长度限制在1-18位" },
        ],
      },
    };
  },
  created() {
    this.toLogin();
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (
          valid &&
          this.ruleForm.username == "qcxAdmin" &&
          this.ruleForm.pass == "quchuxing"
        ) {
          this.$router.push("/index");
          localStorage.setItem("userName", this.ruleForm.username);
        } else {
          this.$message({
            showClose: true,
            message: "用户名或密码错误，请重新输入哦",
            type: "error",
          });
          this.ruleForm.username = "";
          this.ruleForm.pass = "";
          return false;
        }
      });
    },

    toLogin() {
      document.onkeyup = (e) => {
        // 为了更加严谨所以添加了路由路径的判断
        if (e.keyCode === 13) {
          //回车后执行搜索方法
          this.submitForm("ruleForm");
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(../../../assets/background.png) no-repeat;
  background-size: 100% 100vh;
  position: relative;

  .login_logo_head {
    width: 240px;
    height: 160px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .login_con {
    width: 500px;
    height: 300px;
    background: #fff;
    box-shadow: 0 3px 11px 4px #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      height: 32px;
      margin-bottom: 33px;
    }

    .el-input {
      width: 250px;
    }

    .el-button {
      width: 220px;
      margin: 0 auto;
      background: #08c6ad;
      border: none;
      margin-top: 20px;
    }

    .el-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
