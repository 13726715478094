// const baseURL = "https://test-examine.quchuxing.com/examine";
// const baseURLMall = "https://test-examine.quchuxing.com/mall";
const baseURL = "https://examine.quchuxing.com/examine";
const baseURLMall = "https://examine.quchuxing.com/mall";

const reqUrl = {
  imageUpload: `${baseURL}/imageUpload`, // 上传图片
  richImageUpload: `${baseURL}/richImageUpload`, // 文本编辑器上传图片
  insertBoxBatch: `${baseURL}/insertBoxBatch`, // 上传文件

  getProvinceCity: `${baseURL}/getProvinceCity`, // 查询省市

  getDriverList: `${baseURL}/getDriverList`, // 车主审核-列表
  getDriverDetail: `${baseURL}/getCheckDetail`, // 车主审核-详情页

  getDriverCheckList: `${baseURL}/getDriverCheckList`, // 车主审核-审核手动/自动/失效列表
  cancelDetailDriver: `${baseURL}/driver/canceldriver`, // 车主审核-注销按钮
  examineDetailDriver: `${baseURL}/driver/checkResult`, // 车主审核-审核通过按钮
  getAuditingDetail: `${baseURL}/getAuditingDetail`, // 车主审核-待审核列表详情页
  getDriverfindDriverFaceInfoUrl: `${baseURL}/driver/findDriverFaceInfo`, // 车主审核-车主刷脸
  driverResetDriverFaceUrl: `${baseURL}/driver/resetDriverFaceUrl`, // 车主审核-车主刷脸-清空操作

  userBanList: `${baseURL}/driver/bannedList`, // 用户封禁-列表
  addBanList: `${baseURL}/driver/userBanned`, // 用户封禁-添加封禁用户
  liftBan: `${baseURL}/driver/releaseUser`, // 用户封禁-解封操作
  userPartBan: `${baseURL}/banUser/userPartBan`, // 用户封禁-其他功能封禁-添加封禁用户
  getBanRecordList: `${baseURL}/banUser/getBanRecordList`, // 用户封禁-其他功能封禁
  manualRelease: `${baseURL}/banUser/manualRelease`, // 用户封禁-其他功能封禁-解禁

  getWalletList: `${baseURL}/getCashOutList`, // 资产审核-现金提现
  withdrawalUrl: `${baseURL}/cashOutResult`, // 资产审核-现金提现-提现成功或失败的审核结果
  cashOutNotAutoResult: `${baseURL}/cashOutNotAutoResult`, // 资产审核-现金提现-人工通道
  getCashoutReceivedSum: `${baseURL}/getCashoutReceivedSum`, // 资产审核-现金提现-待提现总金额
  getCashOutOnOff: `${baseURL}/getCashOutOnOff`, // 获取自动提现的状态
  setCashOutOnOff: `${baseURL}/setCashOutOnOff`, // 开启自动提现的开关

  getCpleUpperChainList: `${baseURL}/getCpleUpperChain`, // 资产审核-cple积分上链审核
  cpleUpperChainExamine: `${baseURL}/cpleUpperChainExamine`, // 资产审核-cple积分上链审核-操作
  banUserBatchCpleChain: `${baseURL}/banUserBatchCpleChain`, // 资产审核-cple积分上链审核-批量审核
  getUpperChainOnOff: `${baseURL}/getUpperChainOnOff`, // 资产审核-cple积分上链审核-获取自动审核状态
  setUpperChainOnOff: `${baseURL}/setUpperChainOnOff`, // 资产审核-cple积分上链审核-自动审核开关

  getChargeList: `${baseURL}/getChargeList`, // 资产审核-余额充值
  chargeAuditUrl: `${baseURL}/chargeAudit`, // 资产审核-余额充值-操作-审核通过/拒绝
  chargeAuditBackUrl: `${baseURL}/chargeAuditBack`, // 资产审核-余额充值-操作-撤销
  payAuditNftUrl: `${baseURL}/payAudit`, // 资产审核-支付审核-审核支付
  getPayListExamine: `${baseURL}/getPayListExamine`, // 资产审核-支付审核-审核支付-查询接口

  getOutQuotaList: `${baseURL}/getLimitTransferList`, // 转出额度赠送审核-列表
  outQuotaResultUrl: `${baseURL}/getTransferResult`, // 转出额度赠送审核-审核结果操作

  getChainTransactionLog: `${baseURL}/getChainTransactionLog`, // 资产审核-链上记录
  cancelChainTransLog: `${baseURL}/cancelChainTransLog`, // 资产审核-链上记录-修改

  getVersion: `${baseURL}/getVersionMsg`, // 版本管理
  addVersion: `${baseURL}/insertVersionMsg`, // 版本管理-更新版本

  getCpleRate: `${baseURL}/getCpleRate`, // 配置管理-汇率管理-cple
  getUsdtRate: `${baseURL}/getUsdtRate`, // 配置管理-汇率管理-usdt
  setCpleRate: `${baseURL}/setCpleRate`, // 配置管理-汇率管理-设置cple汇率
  setUsdtRate: `${baseURL}/setUsdtRate`, // 配置管理-汇率管理-设置usdt汇率

  getOldBoxContinuationPrice: `${baseURL}/getOldBoxContinuationPrice`, // 配置管理-流量续费价格设置-获取价格
  setOldBoxContinuationPrice: `${baseURL}/setOldBoxContinuationPrice`, // 配置管理-流量续费价格设置-设置价格
  getBoxMonthContinuationPrice: `${baseURL}/getBoxMonthContinuationPrice`, // 配置管理-月卡续费价格设置-获取价格
  setBoxMonthContinuationPrice: `${baseURL}/setBoxMonthContinuationPrice`, // 配置管理-月卡续费价格设置-设置价格

  getDistanceRate: `${baseURL}/getDistanceRate`, // 配置管理-模糊打点直线距离设置-获取
  setDistanceRate: `${baseURL}/setDistanceRate`, // 配置管理-模糊打点直线距离设置-设置

  getMatchBoxAutoCashOutLimit: `${baseURL}/huoCaiHe/getMatchBoxAutoCashOutLimit`, // 配置管理-火财盒奖励自动发放额度-获取
  setMatchBoxAutoCashOutLimit: `${baseURL}/huoCaiHe/setMatchBoxAutoCashOutLimit`, // 配置管理-火财盒奖励自动发放额度-设置

  getAutoCashOutLimit: `${baseURL}/getAutoCashOutLimit`, // 配置管理-用户自动提现额度-获取
  setAutoCashOutLimit: `${baseURL}/setAutoCashOutLimit`, // 配置管理-用户自动提现额度-设置

  getCplePledgeList: `${baseURL}/getCplePledge`, // 行程上链-CPLE质押
  addCplePledge: `${baseURL}/insertCplePledge`, // 行程上链-添加质押
  beforeInsertPledge: `${baseURL}/beforeInsertPledge`, // 行程上链-添加质押-查询质押状态
  unCplePledgeUrl: `${baseURL}/unCplePledge`, // 行程上链-CPLE质押-解除质押操作

  getNFTExamineList: `${baseURL}/getNftExamineList`, // 行程上链-nft审核功能
  examineNft: `${baseURL}/examineNft`, // 行程上链-nft操作审核功能-铸造
  getNftRelationExamineList: `${baseURL}/getNftRelationExamineList`, // 行程上链-nft操作审核功能-关联
  examineNftRelation: `${baseURL}/examineNftRelation`, // 行程上链-nft操作审核功能-关联
  editNftImageUrl: `${baseURL}/editNftImage`, // nft裁剪功能
  imageUploadUrl: `${baseURL}/imageUpload`, // npt裁剪上传

  getRewardList: `${baseURL}/getTravelRewardsList`, // 行程上链-行程奖励列表
  ravelRewardExaimne: `${baseURL}/ravelRewardExaimne`, // 行程上链-行程奖励操作
  getTravelRewardsSum: `${baseURL}/getTravelRewardsSum`, // 行程上链-行程奖励总数

  updateBoxidUrl: `${baseURL}/updateBoxidByPhone`, // 行程上链-趣宝盒数据-强制变更盒子id
  selectBoxidUrl: `${baseURL}/selectBoxidByPhone`, // 行程上链-趣宝盒数据-强制变更盒子id-通过手机号查询盒子id
  queryBoxIsUsedUrl: `${baseURL}/queryBoxIsUsed`, // 行程上链-趣宝盒数据-强制绑定盒子-判断盒子是否被绑定
  updateWalletUrl: `${baseURL}/updateWalletByPhone`, // 行程上链-趣宝盒数据-强制变更钱包地址
  selectWalletUrl: `${baseURL}/selectByWalletPhone`, // 行程上链-趣宝盒数据-强制变更钱包地址-通过手机号查询钱包地址
  emptyWalletByPhoneUrl: `${baseURL}/emptyWalletByPhone`, // 行程上链-趣宝盒数据-强制变更钱包地址-置空

  getNextBatchId: `${baseURL}/getNextBatchId`, // 行程上链-趣宝盒数据-趣宝盒ID库-获取默认批次
  getBoxEntryLogList: `${baseURL}/getBoxEntryLogList`, // 行程上链-趣宝盒数据-趣宝盒ID库-获取列表数据
  editBoxEntryLog: `${baseURL}/editBoxEntryLog`, // 行程上链-趣宝盒数据-趣宝盒ID库-编辑日期

  getBoxList: `${baseURL}/selectBoxByBoxid`, // 行程上链-趣宝盒数据-盒子状态
  selectLastRequestBox: `${baseURL}/selectLastRequestBox`, // 行程上链-趣宝盒数据-盒子状态-获取列表数据
  boxResetCpStatus: `${baseURL}/box/resetCpStatus`, // 行程上链-趣宝盒数据-盒子状态-设置盒子语音状态

  getUserSuperior: `${baseURL}/user/userRelationQuery`, // 行程上链-趣宝盒数据-上下级关系

  listAllOrder: `${baseURLMall}/admin/boxOrder/listAllOrder`, // 行程上链-趣宝盒数据-以旧换新-全部
  listToPayOrder: `${baseURLMall}/admin/boxOrder/listToPayOrder`, // 行程上链-趣宝盒数据-以旧换新-待付款
  listToShipOrder: `${baseURLMall}/admin/boxOrder/listToShipOrder`, // 行程上链-趣宝盒数据-以旧换新-待发货
  listShippedOrder: `${baseURLMall}/admin/boxOrder/listShippedOrder`, // 行程上链-趣宝盒数据-以旧换新-待绑定
  listToConfirmOrder: `${baseURLMall}/admin/boxOrder/listToConfirmOrder`, // 行程上链-趣宝盒数据-以旧换新-已完成
  listToCancelOrder: `${baseURLMall}/admin/boxOrder/listToCancelOrder`, // 行程上链-趣宝盒数据-以旧换新-已取消
  shipBoxOrder: `${baseURLMall}/admin/boxOrder/shipBoxOrder`, // 行程上链-趣宝盒数据-以旧换新-发货
  shipBoxOrderAgain: `${baseURLMall}/admin/boxOrder/shipBoxOrderAgain`, // 行程上链-趣宝盒数据-以旧换新-重新发货
  confirmOrder: `${baseURLMall}/admin/boxOrder/confirmOrder`, // 行程上链-趣宝盒数据-以旧换新-手动确认收货
  getHardwareIdByIccId: `${baseURL}/getHardwareIdByIccId`, // 行程上链-趣宝盒数据-以旧换新-查询iccid

  getHardwareUntilTimeList: `${baseURL}/getHardwareUntilTimeList`, // 行程上链-趣宝盒数据-流量续费
  getHardwareUntilTimeDetail: `${baseURL}/getHardwareUntilTimeDetail`, // 行程上链-趣宝盒数据-流量续费-续费历史

  realNameList: `${baseURL}/user/getAuthExamineList`, // 实名认证-列表
  destroyRealName: `${baseURL}/user/authDestroy`, // 实名认证-解除实名认证
  destroyRealNameList: `${baseURL}/user/getAuthDestroyExamineList`, // 实名认证-解除列表

  stringToFileUploadUrl: `${baseURL}/StringToFileUpload`, // 日常管理-公告管理-转html文件
  noticeExamineUrl: `${baseURL}/insertNoticeExamine`, // 日常管理-公告管理-新增公告
  deleteNoticeExamine: `${baseURL}/delateNoticeExamine`, // 日常管理-公告管理-删除
  noticeList: `${baseURL}/queryNoticeListExamine`, // 日常管理-公告管理-列表
  updateNoticeExamine: `${baseURL}/updateNoticeExamine`, // 日常管理-公告管理-修改

  getAllAgentInfo: `${baseURL}/getAllAgentInfo`, // 行程上链-区代市代省代
  insertCityAgent: `${baseURL}/insertCityAgent`, // 行程上链-区代市代省代-添加代理
  deleteCityAgent: `${baseURL}/deleteCityAgent`, // 行程上链-区代市代省代-删除代理
  getCityAgentRewardLog: `${baseURL}/getCityAgentRewardLog`, // 行程上链-区代市代省代-累计收益-收益明细

  agentCount: `${baseURL}/agentCount`, // 行程上链-区代市代省代-用户代理级别查询-代理数量
  getBoxAgentList: `${baseURL}/getBoxAgentList`, // 行程上链-区代市代省代-用户代理级别查询
  getAgentLevelDetail: `${baseURL}/getAgentLevelDetail`, // 行程上链-区代市代省代-用户代理级别查询-详情
  setTempAgent: `${baseURL}/setTempAgent`, // 行程上链-区代市代省代-用户代理级别查询-详情-升级操作
  cancelTempAgent: `${baseURL}/cancelTempAgent`, // 行程上链-区代市代省代-用户代理级别查询-详情-暂停代理操作
  getBoxAgentBalanceDetail: `${baseURL}/getBoxAgentBalanceDetail`, // 行程上链-区代市代省代-用户代理级别查询-详情-列表
  getBoxAgentBalanceSum: `${baseURL}/getBoxAgentBalanceSum`, // 行程上链-区代市代省代-用户代理级别查询-详情-累计收益

  addGoodsURL: `${baseURLMall}/addGoods`, //商城-商品上架-新增商品
  goodsStringToFileUploadUrl: `${baseURL}/goodsStringToFileUpload`, // 商城-商品上架-新增商品--转html文件
  getGoodsInfoList: `${baseURLMall}/getGoodsInfoList`, // 商城-商品上架-列表
  getGoodsDetailById: `${baseURLMall}/getGoodsDetailById`, // 商城-商品上架-列表-查看详情
  onSaleOrOffSale: `${baseURLMall}/onSaleOrOffSale`, // 商城-商品上架-商品上下架
  recommendOrNotGoods: `${baseURLMall}/recommendOrNotGoods`, // 商城-商品上架-设为精选/不精选

  skuAdminDetailUrl: `${baseURLMall}/skuAdmin/sku/detail`, // 商城-编辑商品规格-获取详情
  skuAdminResetUrl: `${baseURLMall}/skuAdmin/sku/reset`, // 商城-编辑商品规格-添加或修改
  skuAdminEditStockUrl: `${baseURLMall}/skuAdmin/sku/update/stock`, // 商城-编辑商品规格-修改库存
  skuAdminEditPriceUrl: `${baseURLMall}/skuAdmin/sku/update/price`, // 商城-编辑商品规格-修改价格
  skuAdminEditStatusUrl: `${baseURLMall}/skuAdmin/sku/update/status`, // 商城-编辑商品规格-修改状态
  updateGoods: `${baseURLMall}/updateGoods`, // 商城-修改或编辑商品

  getAllOrderExamine: `${baseURLMall}/admin/mallOrder/listAllOrder`, // 商城-订单-查询商品列表-全部
  getlistToPayOrderVait: `${baseURLMall}/admin/mallOrder/listToPayOrder`, // 商城-订单-查询商品列表-待支付
  getListToWaitShipOrderVaitDeliver: `${baseURLMall}/admin/mallOrder/listToShipOrder`, // 商城-订单-查询商品列表-等待发货
  getListToShipOrderDelivery: `${baseURLMall}/admin/mallOrder/listShippedOrder`, // 商城-订单-查询商品列表-已发货
  getListToConfirmOrderComplete: `${baseURLMall}/admin/mallOrder/listToConfirmOrder`, // 商城-订单-查询商品列表-已完成
  getListToCancelOrderCancel: `${baseURLMall}/admin/mallOrder/listToCancelOrder`, // 商城-订单-查询商品列表-已取消

  getMallAddressInfo: `${baseURLMall}/admin/mallOrder/getMallAddressInfo`, // 商城-订单-查询商品列表-详情页
  deliverGood: `${baseURLMall}/admin/mallOrder/shipOrder`, // 商城-订单-发货按钮
  refundOrder: `${baseURLMall}/admin/mallOrder/refundOrder`, // 商城-订单-退款

  getGoodsIdList: `${baseURLMall}/getGoodsIdList`, // 商城-获取商品名称（筛选）

  presaleOrderListAllOrder: `${baseURLMall}/admin/presaleOrder/listAllOrder`, // 预售-订单列表-全部
  presaleOrderVait: `${baseURLMall}/admin/presaleOrder/listToPayOrder`, // 预售-订单列表-待支付
  presaleOrderVaitDeliver: `${baseURLMall}/admin/presaleOrder/listToShipOrder`, // 预售-订单列表-等待发货
  presaleOrderDelivery: `${baseURLMall}/admin/presaleOrder/listShippedOrder`, // 预售-订单列表-已发货
  presaleOrderComplete: `${baseURLMall}/admin/presaleOrder/listToConfirmOrder`, // 预售-订单列表-已完成
  presaleOrderCancel: `${baseURLMall}/admin/presaleOrder/listToCancelOrder`, // 预售-订单列表-已取消
  presaleOrderGetRefundList: `${baseURLMall}/admin/presaleOrder/getRefundList`, // 预售-订单列表-退款
  examineAuditBoxRefund: `${baseURL}/auditBoxRefund`, // 预售-订单列表-退款-操作

  getBoxCheckStatistics: `${baseURL}/getBoxCheck`, // 统计-数据汇总比对-激活趣宝盒数据
  getUserCheckStatistics: `${baseURL}/getUserCheck`, // 统计-数据汇总比对-推广用户数据
  getCheckUserDetail: `${baseURL}/getCheckUserDetail`, // 统计-数据汇总比对-推广用户数据-详情
  getRMBCheckStatistics: `${baseURL}/getRMBCheck`, // 统计-数据汇总比对-RMB
  getBalanceHistory: `${baseURL}/getBalanceHistory`, // 统计-数据汇总比对-RMB-详情
  modifyBalance: `${baseURL}/modifyBalance`, // 统计-数据汇总比对-RMB-增加或扣除
  getCPLECheckStatistics: `${baseURL}/getCPLECheck`, // 统计-数据汇总比对-CPLE积分数据
  getCpleHistory: `${baseURL}/getCpleHistory`, // 统计-数据汇总比对-CPLE积分数据-详情
  modifyCPLE: `${baseURL}/modifyCPLE`, // 统计-数据汇总比对-CPLE积分数据-增加或扣除
  getQuotaCheckStatistics: `${baseURL}/getQuotaCheck`, // 统计-数据汇总比对-转出额度
  getQuotaHistory: `${baseURL}/getQuotaHistory`, // 统计-数据汇总比对-转出额度-详情
  modifyQuota: `${baseURL}/modifyQuota`, // 统计-数据汇总比对-转出额度-增加或扣除
  getPledgeCheckStatistics: `${baseURL}/getPledgeCheck`, // 统计-数据汇总比对-CPLE趣宝盒上链数据
  getBillTradeEnum: `${baseURL}/getBillTradeEnum`, // 统计-数据汇总比对-增加或扣除操作-获取枚举类型

  getDailyUserDate: `${baseURL}/getDailyUserDate`, // 统计-单日数据-实名认证
  getDailyDriverDate: `${baseURL}/getDailyDriverDate`, // 统计-单日数据-车主认证
  getDailyHardwareDate: `${baseURL}/getDailyHardwareDate`, // 统计-单日数据-趣宝盒

  // huoCaiHeGetRewardsSum: `${baseURL}/huoCaiHe/getRewardsSum`, // 火财盒-获取待发放奖励的总和
  // huoCaiHeGetBoxRewardsList: `${baseURL}/huoCaiHe/getBoxRewardsList`, // 火财盒-列表数据
  // huoCaiHeGetBoxRewardsLogList: `${baseURL}/huoCaiHe/getBoxRewardsLogList`, // 火财盒-历史列表数据
  // huoCaiHeGetBoxContributionDetail: `${baseURL}/huoCaiHe/getBoxContributionDetail`, // 火财盒-列表数据-详情
  huoCaiHeGetMatchBoxList: `${baseURL}/huoCaiHe/getMatchBoxList`, // 火财盒-待审核列表
  huoCaiHeAuditMatchBox: `${baseURL}/huoCaiHe/auditMatchBox`, // 火财盒-审核操作
  huoCaiHeGetMatchBoxContributionDetail: `${baseURL}/huoCaiHe/getMatchBoxContributionDetail`, // 火财盒-详情
  huoCaiHeGetActivityNOList: `${baseURL}/huoCaiHe/getActivityNOList`, // 火财盒-获取所有的期号列表

  // 行世界部分
  worldTravelSelectCity: `${baseURL}/worldTravel/selectCity`, // 城市管理-查询列表
  worldTravelDeleteCity: `${baseURL}/worldTravel/deleteCity`, // 城市管理-删除
  worldTravelUpdateCity: `${baseURL}/worldTravel/updateCity`, // 城市管理-修改钱包
  worldTravelInsertCity: `${baseURL}/worldTravel/insertCity`, // 城市管理-添加

  worldTravelQueryWorldTravelPledgeList: `${baseURL}/worldTravel/queryWorldTravelPledgeList`, // 用户质押管理-查询
  worldTravelWorldTravelUnPledgeAudit: `${baseURL}/worldTravel/worldTravelUnPledgeAudit`, // 用户质押管理-解押审核操作
  worldTravelQueryPledgeLog: `${baseURL}/worldTravel/queryPledgeLog`, // 用户质押管理-查看质押记录
  worldTravelManualWorldTravelPledge: `${baseURL}/worldTravel/manualWorldTravelPledge`, // 用户质押管理-手动添加质押

  worldTravelSelectProcurementAuth: `${baseURL}/worldTravel/selectProcurementAuth`, // 权限管理-查询
  worldTravelInsertProcurementAuth: `${baseURL}/worldTravel/insertProcurementAuth`, // 权限管理-添加

  travelRegionGetList: `${baseURL}/travelRegion/getList`, // 线路管理
  travelRegionUpdateTravelRegion: `${baseURL}/travelRegion/updateTravelRegion`, // 线路管理-修改多语言
  travelRegionGetUser: `${baseURL}/travelRegion/getUser`, // 线路管理-查询管理员
  travelRegionCreateFixed: `${baseURL}/travelRegion/createFixed`, // 线路管理-新建路线
  travelRegionGetFixedRegionList: `${baseURL}/travelRegion/getFixedRegionList`, // 线路管理-线路列表
  travelRegionUpdateFixedTravel: `${baseURL}/travelRegion/updateFixedTravel`, // 线路管理-修改路线
  travelRegionDeleteFixedTravel: `${baseURL}/travelRegion/deleteFixedTravel`, // 线路管理-删除路线

  worldActivityInfoCreate: `${baseURLMall}/admin/world/activity/info/create`, // 活动管理-发布活动
  worldActivityInfoEdit: `${baseURLMall}/admin/world/activity/info/edit`, // 活动管理-编辑活动
  worldActivityIinfoGetActivityList: `${baseURLMall}/admin/world/activity/info/getActivityList`, // 活动管理-活动列表查询
  worldActivityOrderListOrder: `${baseURLMall}/admin/world/activity/order/listOrder`, // 活动管理-订单列表
  worldTravelCancelActivityOrder: `${baseURL}/worldTravel/cancelActivityOrder`, // 活动管理-订单列表详情页-取消

  worldHotelGetHotelList: `${baseURLMall}/admin/world/hotel/getHotelList`, // 酒店管理-酒店列表
  worldHotelAddHotel: `${baseURLMall}/admin/world/hotel/addHotel`, // 酒店管理-发布酒店
  worldHotelGetHotelDetail: `${baseURLMall}/admin/world/hotel/getHotelDetail`, // 酒店管理-详情
  worldHotelUpdateSkuStock: `${baseURLMall}/admin/world/hotel/updateSkuStock`, // 酒店管理-详情-修改库存
  worldHotelGetOrderList: `${baseURLMall}/admin/world/hotel/getOrderList`, // 酒店管理-订单列表
  worldConfirmOrder: `${baseURLMall}/admin/world/hotel/confirmOrderByAuditor`, // 酒店管理-订单列表-详情-确认订单操作
  worldCancelOrderByAuditor: `${baseURLMall}/admin/world/hotel/cancelOrderByAuditor`, // 酒店管理-订单列表-详情-取消订单操作
  worldHotelOnSale: `${baseURLMall}/admin/world/hotel/hotelOnSale`, // 酒店管理-上下架操作
  worldGetHotelListOption: `${baseURLMall}/admin/world/hotel/getHotelListOption`, // 查询酒店
  worldHotelEditHotel: `${baseURLMall}/admin/world/hotel/editHotel`, // 编辑酒店
  worldHotelGetRoomListByHotelId: `${baseURLMall}/admin/world/hotel/getRoomListByHotelId`, // 编辑房型-获取
  worldHotelEditRoom: `${baseURLMall}/admin/world/hotel/editRoom`, // 编辑房型-提交

  worldCreateAddSightseeing: `${baseURLMall}/admin/world/sightseeing/addSightseeing`, // 游玩项目-发布游玩项目
  worldGetSightseeingList: `${baseURLMall}/admin/world/sightseeing/getSightseeingList`, // 游玩项目-发布游玩项目-列表
  worldGetWorldSightseeingDetail: `${baseURLMall}/admin/world/sightseeing/getWorldSightseeingDetail`, // 游玩项目-发布游玩项目-列表-详情
  worldSightseeingOnSale: `${baseURLMall}/admin/world/sightseeing/sightseeingOnSale`, // // 游玩项目-发布游玩项目-列表-详情-上下架
  worldUpdateSightseeingSkuStock: `${baseURLMall}/admin/world/sightseeing/updateSightseeingSkuStock`, // 游玩项目-发布游玩项目-列表-详情-编辑库存
  worldGetSightseeingInfoDetailById: `${baseURLMall}/admin/world/sightseeing/getSightseeingInfoDetailById`, // 游玩项目-编辑游玩项目-获取信息
  worldEditSightseeing: `${baseURLMall}/admin/world/sightseeing/editSightseeing`, // 游玩项目-编辑游玩项目
  worldPlayGetOrderList: `${baseURLMall}/admin/world/sightseeing/getOrderList`, // 游玩项目-订单管理
  worldGetSightseeingListOption: `${baseURLMall}/admin/world/sightseeing/getSightseeingListOption`, // 游玩项目-订单管理-项目查询
};

const reqUrlAgent = {
  sendCodeUrl: `${baseURL}/sendSmsExamine`, // 登录-验证码
  agentLoginCodeUrl: `${baseURL}/phone/login`, // 手机验证码登录
  agentLoginPassUrl: `${baseURL}/admin/login`, // 密码登录
  modifyPassUrl: `${baseURL}/modify`, // 修改密码
  outLoginUrl: `${baseURL}/logout`, // 退出登录

  cityAgentgetCityRealNameCount: `${baseURL}/cityAgent/getCityRealNameCount`, // 实名认证-图表数据
  cityAgentgetCityRealNameDetail: `${baseURL}/cityAgent/getCityRealNameDetail`, // 实名认证-列表数据

  AgentgetCityDriverCount: `${baseURL}/cityAgent/getCityDriverCount`, // 车主认证-图表数据
  AgentgetCityDriverDetail: `${baseURL}/cityAgent/getCityDriverDetail`, // 车主认证-列表数据

  AgentgetCityBoxCount: `${baseURL}/cityAgent/getCityBoxCount`, // 趣宝盒收益-图表数据
  AgentgetCityBoxDetail: `${baseURL}/cityAgent/getCityBoxDetail`, // 趣宝盒收益-列表数据

  AgentgetCityNFTCount: `${baseURL}/cityAgent/getCityNFTCount`, // NFT头像制作收益-图表数据
  AgentgetCityNFTDetail: `${baseURL}/cityAgent/getCityNFTDetail`, // NFT头像制作收益-列表数据

  AgentgetTravelDriverCount: `${baseURL}/cityAgent/getTravelDriverCount`, // 车主行程-图表数据
  AgentgetTravelDriverDetail: `${baseURL}/cityAgent/getTravelDriverDetail`, // 车主行程-列表数据

  AgentgetTravelPassCount: `${baseURL}/cityAgent/getTravelPassCount`, // 乘客行程-图表数据
  AgentgetTravelPassDetail: `${baseURL}/cityAgent/getTravelPassDetail`, // 乘客行程-图表数据

  AgentgetTodayCityCount: `${baseURL}/cityAgent/getTodayCityCount`, // 今日数据
};

const reqUrlInternation = {
  getAuditingList: `${baseURL}/getAuditingList`, // 实名认证查询
  auditOverSeaUser: `${baseURL}/auditOverSeaUser`, // 实名认证审核
  alertAuthMsg: `${baseURL}/alertAuthMsg`, // 实名信息补全
  resetOverseasAuth: `${baseURL}/resetOverseasAuth`, // 拒绝后重置审核

  getDriverAuditingList: `${baseURL}/getDriverAuditingList`, // 车主认证查询
  auditOverSeaDriver: `${baseURL}/auditOverSeaDriver`, // 车主认证审核
  resetOverseasDriver: `${baseURL}/resetOverseasDriver`, // 车主认证重置
};

export { reqUrl, reqUrlAgent, reqUrlInternation };
