<template>
  <div>
    <router-view v-if="isRefresh" />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRefresh: true,
    };
  },
  methods: {
    reload() {
      this.isRefresh = false;
      this.$nextTick(() => {
        this.isRefresh = true;
      });
    },
  },

  mounted() {
    if (!localStorage.getItem("userName")) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
}

// 主页index部分中的底部style
.aside_footer {
  width: 100%;
  height: 75px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .el-dropdown {
    height: 17px;
  }

  .aside_footer_head_img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    color: #202226;
    margin-right: 12px;
  }

  .aside_footer_dian_img {
    width: 17px;
    height: 17px;
  }
}

.table_con {
  height: calc(100vh - 150px);
  margin-top: 15px;
}

.header_title_agent {
  height: 80px;
  display: flex;
  align-items: center;

  p {
    font-size: 22px;
    color: #202226;
    margin-right: 8px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #7d828c;
  }
}

.el-select-dropdown {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px !important;
}

.el-date-picker.has-sidebar {
  border-radius: 20px !important;
  overflow: hidden !important;
}

.el-picker-panel__sidebar {
  border-radius: 20px !important;
}

// .el-upload--picture-card 控制加号部分
.disabled .el-upload--picture-card {
  display: none !important;
}

// .v-modal {
//   z-index: -1 !important;
// }
</style>
