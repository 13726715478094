import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../Index.vue";
import LoginExamine from "../views/Examine/login";

// 区代理后台
import IndexAgent from "../IndexAgent.vue";
import Login from "../views/Agent/LoginAgent";

// 国际版后台
import IndexInternation from "../IndexInternation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },

  {
    path: "/index",
    name: "index",
    component: Index,
    children: [
      {
        path: "/index",
        name: "Index",
        components: {
          main: () => import("../views/Examine/assetReview/index.vue"),
        },
      },

      // ---------------------------------------------------------------
      // 行程上链
      {
        path: "/BoxData", // 盒子数据
        name: "boxData",
        components: {
          main: () => import("../views/Examine/assetReview/box/boxData.vue"),
        },
      },
      {
        path: "/Superior", // 上下级关系
        name: "superior",
        components: {
          main: () => import("../views/Examine/assetReview/box/superior.vue"),
        },
      },
      {
        path: "/ForceReplaceID", // 强制绑定/替换盒子ID
        name: "forceReplaceID",
        components: {
          main: () => import("../views/Examine/assetReview/box/replaceID.vue"),
        },
      },
      {
        path: "/TradeIn", // 以旧领新/全部
        name: "tradeIn",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/tradeIn/all.vue"),
        },
      },
      {
        path: "/TradeInVait", // 以旧领新/待付款
        name: "tradeInVait",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/tradeIn/vait.vue"),
        },
      },
      {
        path: "/TradeInVaitDeliver", // 以旧领新/待发货
        name: "tradeInVaitDeliver",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/tradeIn/vaitDeliver.vue"),
        },
      },
      {
        path: "/TradeInVaitBind", // 以旧领新/待绑定
        name: "tradeInVaitBin",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/tradeIn/vaitBind.vue"),
        },
      },
      {
        path: "/TradeInComplete", // 以旧领新/已完成
        name: "tradeInComplete",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/tradeIn/complete.vue"),
        },
      },
      {
        path: "/TradeInCancel", // 以旧领新/已取消
        name: "tradeInCancel",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/tradeIn/cancel.vue"),
        },
      },

      {
        path: "/TrafficRenewal", // 流量续费/全部
        name: "trafficRenewal",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/TrafficRenewal/all.vue"),
        },
      },
      {
        path: "/TradeInExpired", // 流量续费/已过期
        name: "tradeInExpired",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/expired.vue"
            ),
        },
      },
      {
        path: "/TradeInVaitRenew", // 流量续费/待续费
        name: "tradeInVaitRenew",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/vaitRenew.vue"
            ),
        },
      },
      {
        path: "/TradeInOkRenew", // 流量续费/已续费
        name: "tradeInOkRenew",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/okRenew.vue"
            ),
        },
      },
      {
        path: "/TradeInVoidEd", // 流量续费/已报废
        name: "tradeInVoidEd",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/voidEd.vue"
            ),
        },
      },

      {
        path: "/TrafficRenewal/detail/okRenew", // 流量续费-详情-已续费
        name: "trafficRenewalDetailOkRenew",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/detail/okRenew.vue"
            ),
        },
      },
      {
        path: "/TrafficRenewal/detail/VaitRenew", // 流量续费-详情-待续费
        name: "trafficRenewalDetailVaitRenew",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/detail/vaitRenew.vue"
            ),
        },
      },
      {
        path: "/TrafficRenewal/detail/Expired", // 流量续费-详情-已过期
        name: "trafficRenewalDetailExpired",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/detail/expired.vue"
            ),
        },
      },
      {
        path: "/TrafficRenewal/detail/VoidEd", // 流量续费-详情-已报废
        name: "trafficRenewalDetailVoidEd",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/box/TrafficRenewal/detail/voidEd.vue"
            ),
        },
      },
      {
        path: "/BoxIDLibrary", // 流量续费-详情-已报废
        name: "boxIDLibrary",
        components: {
          main: () =>
            import("../views/Examine/assetReview/box/boxIDLibrary.vue"),
        },
      },

      // {
      //   path: "/FireWealthBox", // 火财盒
      //   name: "fireWealthBox",
      //   components: {
      //     main: () =>
      //       import("../views/Examine/assetReview/box/FireWealthBox/index.vue"),
      //   },
      // },
      // {
      //   path: "/FireWealthBoxHistory", // 火财盒
      //   name: "fireWealthBoxHistory",
      //   components: {
      //     main: () =>
      //       import(
      //         "../views/Examine/assetReview/box/FireWealthBox/history.vue"
      //       ),
      //   },
      // },
      // {
      //   path: "/FireWealthBox/Detail", // 火财盒-详情
      //   name: "fireWealthBoxDetail",
      //   components: {
      //     main: () =>
      //       import("../views/Examine/assetReview/box/FireWealthBox/detail.vue"),
      //   },
      // },

      {
        path: "/FireWealthBoxVait", // 火财盒-待审核
        name: "FireWealthBoxVait",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/FireWealthBox/vait.vue"),
        },
      },
      {
        path: "/FireWealthBoxHistory", // 火财盒-历史审核
        name: "fireWealthBoxHistory",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/FireWealthBox/history.vue"),
        },
      },
      {
        path: "/FireWealthBox/Detail", // 火财盒-详情
        name: "fireWealthBoxDetail",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/FireWealthBox/detail.vue"),
        },
      },

      {
        path: "/CPLEpledgeIng", // CPLE质押-质押中
        name: "CPLEpledgeIng",
        components: {
          main: () =>
            import("../views/Examine/assetReview/CPLEpledge/CPLEpledgeIng.vue"),
        },
      },
      {
        path: "/CPLEpledgeApplyIng", // CPLE质押-申请质押中
        name: "CPLEpledgeApplyIng",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/CPLEpledge/CPLEpledgeApplyIng.vue"
            ),
        },
      },
      {
        path: "/CPLEpledgeEd", // CPLE质押-已解押
        name: "CPLEpledgeEd",
        components: {
          main: () =>
            import("../views/Examine/assetReview/CPLEpledge/CPLEpledgeEd.vue"),
        },
      },
      {
        path: "/AddCPLEpledge", // 添加质押
        name: "addCPLEpledge",
        components: {
          main: () =>
            import("../views/Examine/assetReview/CPLEpledge/addPledge.vue"),
        },
      },
      {
        path: "/ForceReplaceWallet", // 强制绑定/替换钱包地址
        name: "forceReplaceWallet",
        components: {
          main: () =>
            import("../views/Examine/assetReview/CPLEpledge/replaceWallet.vue"),
        },
      },

      {
        path: "/CPLERewardVait", // CPLE每日奖励下发(待发放)
        name: "cpleRewardVait",
        components: {
          main: () =>
            import("../views/Examine/assetReview/cpleReward/vait.vue"),
        },
      },
      {
        path: "/CPLERewardHistory", // CPLE每日奖励下发（历史）
        name: "cpleRewardHistory",
        components: {
          main: () =>
            import("../views/Examine/assetReview/cpleReward/history.vue"),
        },
      },

      {
        path: "/NFTRelationExamine", // NFT铸造审核
        name: "NFTRelationExamine",
        components: {
          main: () =>
            import("../views/Examine/assetReview/NFTExamine/relation.vue"),
        },
      },
      {
        path: "/NFTCastingExamine", // NFT关联审核
        name: "NFTCastingExamine",
        components: {
          main: () =>
            import("../views/Examine/assetReview/NFTExamine/casting.vue"),
        },
      },

      {
        path: "/AgentIndex", // 代理/运营-代理
        name: "agentIndex",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/AgentOperation/AgentIndex.vue"
            ),
        },
      },
      {
        path: "/AgentIndex/detail", // 代理/运营-代理/详情
        name: "agentIndexDetail",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/AgentOperation/AgentIndexDetail.vue"
            ),
        },
      },

      {
        path: "/cityOperation", // 代理/运营-市运营
        name: "cityOperation",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/AgentOperation/cityOperation.vue"
            ),
        },
      },
      {
        path: "/provinceOperation", // 代理/运营-省运营
        name: "provinceOperation",
        components: {
          main: () =>
            import(
              "../views/Examine/assetReview/AgentOperation/provinceOperation.vue"
            ),
        },
      },

      // ---------------------------------------------------------------
      // 资产审核
      {
        path: "/BalanceVaitList", // 余额充值-待审核列表
        name: "balanceVaitList",
        components: {
          main: () => import("../views/Examine/travelWinding/balance/vait.vue"),
        },
      },
      {
        path: "/BalanceSuccessList", // 余额充值-审核通过列表
        name: "balanceSuccessList",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/balance/success.vue"),
        },
      },
      {
        path: "/BalanceFailList", // 余额充值-审核拒绝列表
        name: "balanceFailList",
        components: {
          main: () => import("../views/Examine/travelWinding/balance/fail.vue"),
        },
      },
      {
        path: "/chainRecords", // 链上记录
        name: "chainRecords",
        components: {
          main: () => import("../views/Examine/travelWinding/chainRecords.vue"),
        },
      },
      {
        path: "/PaymentVaitExamine", // 标记支付审核-待审核列表
        name: "paymentVaitExamine",
        components: {
          main: () => import("../views/Examine/travelWinding/payment/vait.vue"),
        },
      },
      {
        path: "/PaymentSuccessExamine", // 标记支付审核-审核通过列表
        name: "paymentSuccessExamine",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/payment/success.vue"),
        },
      },
      {
        path: "/PaymentFailExamine", // 标记支付审核-审核失败列表
        name: "paymentFailExamine",
        components: {
          main: () => import("../views/Examine/travelWinding/payment/fail.vue"),
        },
      },
      {
        path: "/CashWithdrawal", // 现金提现
        name: "cashWithdrawal",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/cashWithdrawal/index.vue"),
        },
      },
      {
        path: "/WithdrawalSoon", // 现金提现-待提现
        name: "withdrawalSoon",
        components: {
          main: () =>
            import(
              "../views/Examine/travelWinding/cashWithdrawal/withdrawalSoon.vue"
            ),
        },
      },
      {
        path: "/WithdrawalIng", // 现金提现-提现中
        name: "withdrawalIng",
        components: {
          main: () =>
            import(
              "../views/Examine/travelWinding/cashWithdrawal/withdrawalIng.vue"
            ),
        },
      },
      {
        path: "/WithdrawalSuccess", // 现金提现-提现成功
        name: "withdrawalSuccess",
        components: {
          main: () =>
            import(
              "../views/Examine/travelWinding/cashWithdrawal/withdrawalSuccess.vue"
            ),
        },
      },
      {
        path: "/WithdrawalFail", // 现金提现-提现失败
        name: "withdrawalFail",
        components: {
          main: () =>
            import(
              "../views/Examine/travelWinding/cashWithdrawal/withdrawalFail.vue"
            ),
        },
      },
      {
        path: "/CPLEIntegralVait", // CPLE积分上链审核-待审核
        name: "CPLEIntegralVait",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/cpleIntegral/Vait.vue"),
        },
      },
      {
        path: "/CPLEIntegralHistory", // CPLE积分上链审核-已审核
        name: "CPLEIntegralHistory",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/cpleIntegral/History.vue"),
        },
      },
      {
        path: "/QuotaGift", // 转出额度赠送审核-待审核
        name: "quotaGift",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/quotaGift/index.vue"),
        },
      },
      {
        path: "/QuotaGiftSuccess", // 转出额度赠送审核-审核通过
        name: "quotaGiftSuccess",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/quotaGift/success.vue"),
        },
      },
      {
        path: "/QuotaGiftFail", // 转出额度赠送审核-审核不通过
        name: "quotaGiftFail",
        components: {
          main: () =>
            import("../views/Examine/travelWinding/quotaGift/fail.vue"),
        },
      },

      // ---------------------------------------------------------------
      // 趣宝商城
      {
        path: "/MallPayListVait", // 待付款订单
        name: "mallPayListVait",
        components: {
          main: () => import("../views/Examine/mall/Order/vait.vue"),
        },
      },
      {
        path: "/MallPayListVaitDeliver", // 待发货订单
        name: "mallPayListVaitDeliver",
        components: {
          main: () => import("../views/Examine/mall/Order/vaitDeliver.vue"),
        },
      },
      {
        path: "/MallPayListDeliver", // 已发货订单
        name: "mallPayListDeliver",
        components: {
          main: () => import("../views/Examine/mall/Order/delivery.vue"),
        },
      },
      {
        path: "/MallPayListComplete", // 已完成订单
        name: "mallPayListComplete",
        components: {
          main: () => import("../views/Examine/mall/Order/complete.vue"),
        },
      },
      {
        path: "/MallPayListCancel", // 已取消订单
        name: "mallPayListCancel",
        components: {
          main: () => import("../views/Examine/mall/Order/cancel.vue"),
        },
      },
      {
        path: "/MallOrderDetail/vait", // 趣宝盒订单-详情页-等待付款
        name: "mallOrderDetail/vait",
        components: {
          main: () => import("../views/Examine/mall/Order/detail/vait.vue"),
        },
      },
      {
        path: "/MallOrderDetail/vaitDeliver", // 趣宝盒订单-详情页-等待发货
        name: "mallOrderDetail/vaitDeliver",
        components: {
          main: () =>
            import("../views/Examine/mall/Order/detail/vaitDeliver.vue"),
        },
      },
      {
        path: "/MallOrderDetail/delivery", // 趣宝盒订单-详情页-已发货
        name: "mallOrderDetail/delivery",
        components: {
          main: () => import("../views/Examine/mall/Order/detail/delivery.vue"),
        },
      },
      {
        path: "/MallOrderDetail/complete", // 趣宝盒订单-详情页-交易完成
        name: "mallOrderDetail/complete",
        components: {
          main: () => import("../views/Examine/mall/Order/detail/complete.vue"),
        },
      },
      {
        path: "/MallOrderDetail/cancel", // 趣宝盒订单-详情页-已取消
        name: "mallOrderDetail/cancel",
        components: {
          main: () => import("../views/Examine/mall/Order/detail/cancel.vue"),
        },
      },

      {
        path: "/GoodsShelves", // 商品上架
        name: "goodsShelves",
        components: {
          main: () => import("../views/Examine/mall/Goods/shelves.vue"),
        },
      },
      {
        path: "/GoodsShelvesEdit", // 商品上架-修改与编辑商品
        name: "goodsShelvesEdit",
        components: {
          main: () => import("../views/Examine/mall/Goods/editGoodsInfo.vue"),
        },
      },
      {
        path: "/GoodsList", // 商品管理列表
        name: "GoodsList",
        components: {
          main: () => import("../views/Examine/mall/Goods/list.vue"),
        },
      },

      {
        path: "/GoodsShelves/edit/goods", // 商品编辑
        name: "goodsShelvesEditgoods",
        components: {
          main: () => import("../views/Examine/mall/Goods/editGoods.vue"),
        },
      },

      // 商城-趣宝盒预售
      {
        path: "/boxPresellVaitOrder", // 趣宝盒预售订单-待付款
        name: "boxPresellVaitOrder",
        components: {
          main: () => import("../views/Examine/mall/boxPresell/Order/vait.vue"),
        },
      },
      {
        path: "/boxPresellVaitDeliveryOrder", // 趣宝盒预售订单-待发货
        name: "boxPresellVaitDeliveryOrder",
        components: {
          main: () =>
            import("../views/Examine/mall/boxPresell/Order/vaitDeliver.vue"),
        },
      },
      {
        path: "/boxPresellDeliverOrder", // 趣宝盒预售订单-已发货
        name: "boxPresellDeliverOrder",
        components: {
          main: () =>
            import("../views/Examine/mall/boxPresell/Order/delivery.vue"),
        },
      },
      {
        path: "/boxPresellCompleteOrder", // 趣宝盒预售订单-已完成
        name: "boxPresellCompleteOrder",
        components: {
          main: () =>
            import("../views/Examine/mall/boxPresell/Order/complete.vue"),
        },
      },
      {
        path: "/boxPresellCancelOrder", // 趣宝盒预售订单-已取消
        name: "boxPresellCancelOrder",
        components: {
          main: () =>
            import("../views/Examine/mall/boxPresell/Order/cancel.vue"),
        },
      },
      {
        path: "/boxPresellRefundOrderList", // 趣宝盒预售订单-退款
        name: "boxPresellRefundOrderList",
        components: {
          main: () => import("../views/Examine/mall/boxPresell/refund.vue"),
        },
      },
      {
        path: "/YSOrderDetail/vait", // 趣宝盒订单-详情页-等待付款
        name: "YSOrderDetail/vait",
        components: {
          main: () =>
            import("../views/Examine/mall/boxPresell/Order/detail/vait.vue"),
        },
      },
      {
        path: "/YSOrderDetail/vaitDeliver", // 趣宝盒订单-详情页-等待发货
        name: "YSOrderDetail/vaitDeliver",
        components: {
          main: () =>
            import(
              "../views/Examine/mall/boxPresell/Order/detail/vaitDeliver.vue"
            ),
        },
      },
      {
        path: "/YSOrderDetail/delivery", // 趣宝盒订单-详情页-已发货
        name: "YSOrderDetail/delivery",
        components: {
          main: () =>
            import(
              "../views/Examine/mall/boxPresell/Order/detail/delivery.vue"
            ),
        },
      },
      {
        path: "/YSOrderDetail/complete", // 趣宝盒订单-详情页-交易完成
        name: "YSOrderDetail/complete",
        components: {
          main: () =>
            import(
              "../views/Examine/mall/boxPresell/Order/detail/complete.vue"
            ),
        },
      },
      {
        path: "/YSOrderDetail/cancel", // 趣宝盒订单-详情页-已取消
        name: "YSOrderDetail/cancel",
        components: {
          main: () =>
            import("../views/Examine/mall/boxPresell/Order/detail/cancel.vue"),
        },
      },

      // ---------------------------------------------------------------
      // 日常管理
      {
        path: "/AuditList", // 车主审核-审核列表查询
        name: "auditList",
        components: {
          main: () => import("../views/Examine/everyday/Owner/list.vue"),
        },
      },
      {
        path: "/AuditListDetails", // 车主审核-审核列表查询--详情页
        name: "auditListDetails",
        components: {
          main: () => import("../views/Examine/everyday/Owner/details.vue"),
        },
      },
      {
        path: "/AuditStatusList", // 车主审核-车主手动/自动/失效审核列表
        name: "auditStatusList",
        components: {
          main: () => import("../views/Examine/everyday/Owner/status.vue"),
        },
      },
      {
        path: "/AuditStatusListDetails", // 车主审核-车主手动/自动/失效审核列表--审核详情页
        name: "auditStatusListDetails",
        components: {
          main: () =>
            import("../views/Examine/everyday/Owner/statusDetails.vue"),
        },
      },
      {
        path: "/AuditFaceScan", // 车主审核-车主刷脸存档
        name: "auditFaceScan",
        components: {
          main: () =>
            import("../views/Examine/everyday/Owner/AuditFaceScan.vue"),
        },
      },
      {
        path: "/ArtificialRealName", // 实名认证审核--人工实名认证
        name: "artificialRealName",
        components: {
          main: () =>
            import("../views/Examine/everyday/realName/artificial.vue"),
        },
      },
      {
        path: "/RealNameList", // 实名认证审核--实名认证列表
        name: "realNameList",
        components: {
          main: () => import("../views/Examine/everyday/realName/list.vue"),
        },
      },
      {
        path: "/RelieveRealName", // 实名认证审核--解除实名列表
        name: "relieveRealName",
        components: {
          main: () => import("../views/Examine/everyday/realName/relieve.vue"),
        },
      },
      {
        path: "/Banner", // banner管理
        name: "banner",
        components: {
          main: () => import("../views/Examine/everyday/banner.vue"),
        },
      },
      {
        path: "/Edition", // 版本管理
        name: "edition",
        components: {
          main: () => import("../views/Examine/everyday/edition.vue"),
        },
      },
      {
        path: "/ConfigurationRate", // 配置管理
        name: "configurationRate",
        components: {
          main: () =>
            import("../views/Examine/everyday/configuration/rate.vue"),
        },
      },
      {
        path: "/Notice", // 公告管理
        name: "notice",
        components: {
          main: () => import("../views/Examine/everyday/notice.vue"),
        },
      },
      {
        path: "/UserBanLogin", // 用户登陆封禁管理
        name: "UserBanLogin",
        components: {
          main: () => import("../views/Examine/everyday/userBanLogin.vue"),
        },
      },
      {
        path: "/UserBanFeature", // 用户其他封禁管理
        name: "UserBanFeature",
        components: {
          main: () => import("../views/Examine/everyday/userBanFeature.vue"),
        },
      },

      // ---------------------------------------------------------------
      // 行程管理-手机号
      {
        path: "/TripPhone",
        name: "tripPhone",
        components: {
          main: () => import("../views/Examine/trip/phone.vue"),
        },
      },
      // 行程管理-城市
      {
        path: "/TripCity",
        name: "tripCity",
        components: {
          main: () => import("../views/Examine/trip/city.vue"),
        },
      },

      // ---------------------------------------------------------------
      // 统计-大数据看板
      {
        path: "/StatisticsDataBoard",
        name: "statisticsDataBoard",
        components: {
          main: () => import("../views/Examine/statistics/dataBoard.vue"),
        },
      },
      // 统计-数据汇总比对
      {
        path: "/StatisticsDataCompare",
        name: "statisticsDataCompare",
        components: {
          main: () => import("../views/Examine/statistics/dataCompare.vue"),
        },
      },
      // 统计-个人数据比对
      {
        path: "/StatisticsDataUserCompare",
        name: "StatisticsDataUserCompare",
        components: {
          main: () => import("../views/Examine/statistics/userCompare.vue"),
        },
      },
      // 统计-单日数据
      {
        path: "/StatisticsOneDayData",
        name: "statisticsOneDayData",
        components: {
          main: () => import("../views/Examine/statistics/oneDayData.vue"),
        },
      },

      // ---------------------------------------------------------------
      // 行世界-权限管理
      {
        path: "/worldAuthority",
        name: "worldAuthority",
        components: {
          main: () => import("../views/Examine/qcxWorld/authority.vue"),
        },
      },
      // 行世界-城市管理
      {
        path: "/cityManagement",
        name: "cityManagement",
        components: {
          main: () => import("../views/Examine/qcxWorld/city.vue"),
        },
      },
      // 行世界-用户质押管理
      {
        path: "/worldUserPledgeCreate",
        name: "worldUserPledgeCreate",
        components: {
          main: () => import("../views/Examine/qcxWorld/userPledge/create.vue"),
        },
      },
      {
        path: "/worldUserPledgeNotLock",
        name: "worldUserPledgeNotLock",
        components: {
          main: () =>
            import("../views/Examine/qcxWorld/userPledge/notLock.vue"),
        },
      },
      {
        path: "/worldUserPledgeLocking",
        name: "worldUserPledgeLocking",
        components: {
          main: () =>
            import("../views/Examine/qcxWorld/userPledge/locking.vue"),
        },
      },
      {
        path: "/worldUserPledgeFinish",
        name: "worldUserPledgeFinish",
        components: {
          main: () => import("../views/Examine/qcxWorld/userPledge/finish.vue"),
        },
      },
      {
        path: "/worldUserPledgeVaitAudit",
        name: "worldUserPledgeVaitAudit",
        components: {
          main: () => import("../views/Examine/qcxWorld/userPledge/vait.vue"),
        },
      },
      {
        path: "/worldUserPledgeFailAudit",
        name: "worldUserPledgeFailAudit",
        components: {
          main: () => import("../views/Examine/qcxWorld/userPledge/fail.vue"),
        },
      },

      // 行世界-线路管理
      {
        path: "/worldLine",
        name: "worldLine",
        components: {
          main: () => import("../views/Examine/qcxWorld/worldLine/index.vue"),
        },
      },
      // 行世界-线路管理-新建线路
      {
        path: "/worldLine/AddLine",
        name: "worldLineAddLine",
        components: {
          main: () => import("../views/Examine/qcxWorld/worldLine/addLine.vue"),
        },
      },
      // 行世界-线路管理-修改线路
      {
        path: "/worldLine/EditLine",
        name: "worldLineEditLine",
        components: {
          main: () =>
            import("../views/Examine/qcxWorld/worldLine/editLine.vue"),
        },
      },

      // 行世界-活动管理-发布管理-发布
      {
        path: "/worldActivityCreate",
        name: "worldActivityCreate",
        components: {
          main: () => import("../views/Examine/qcxWorld/Activity/create.vue"),
        },
      },
      // 行世界-活动管理-发布管理-活动列表
      {
        path: "/worldActivityList",
        name: "worldActivityList",
        components: {
          main: () =>
            import("../views/Examine/qcxWorld/Activity/activityList.vue"),
        },
      },
      // 行世界-活动管理-发布管理-详情
      {
        path: "/worldActivityDetail",
        name: "worldActivityDetail",
        components: {
          main: () => import("../views/Examine/qcxWorld/Activity/detail.vue"),
        },
      },

      // 行世界-活动管理-订单管理
      {
        path: "/worldActivityOrder",
        name: "worldActivityOrder",
        components: {
          main: () =>
            import("../views/Examine/qcxWorld/Activity/Order/index.vue"),
        },
      },
      // 行世界-活动管理-订单管理-详情
      {
        path: "/worldActivityOrderDetail",
        name: "worldActivityOrderDetail",
        components: {
          main: () =>
            import("../views/Examine/qcxWorld/Activity/Order/detail.vue"),
        },
      },

      // 行世界-酒店管理-发布管理-发布
      {
        path: "/worldHotelCreate",
        name: "worldHotelCreate",
        components: {
          main: () => import("../views/Examine/qcxWorld/Hotel/create.vue"),
        },
      },
      // 行世界-酒店管理-发布管理-酒店列表
      {
        path: "/worldHotelList",
        name: "worldHotelList",
        components: {
          main: () => import("../views/Examine/qcxWorld/Hotel/list.vue"),
        },
      },
      // 行世界-酒店管理-发布管理-详情
      {
        path: "/worldHotelDetail",
        name: "worldHotelDetail",
        components: {
          main: () => import("../views/Examine/qcxWorld/Hotel/detail.vue"),
        },
      },
      // 行世界-酒店管理-发布管理-详情-编辑房型
      {
        path: "/worldHotelDetailEditHouse",
        name: "worldHotelDetailEditHouse",
        components: {
          main: () => import("../views/Examine/qcxWorld/Hotel/editHouse.vue"),
        },
      },
      // 行世界-酒店管理-订单列表
      {
        path: "/worldHotelOrderList",
        name: "worldHotelOrderList",
        components: {
          main: () => import("../views/Examine/qcxWorld/Hotel/Order/list.vue"),
        },
      },
      // 行世界-酒店管理-订单列表-查看详情
      {
        path: "/worldHotelOrderDetail",
        name: "worldHotelOrderDetail",
        components: {
          main: () =>
            import("../views/Examine/qcxWorld/Hotel/Order/detail.vue"),
        },
      },
      // 行世界-酒店管理-订单管理
      {
        path: "/worldHotelOrderDataList",
        name: "worldHotelOrderDataList",
        components: {
          main: () => import("../views/Examine/qcxWorld/Hotel/Order/index.vue"),
        },
      },

      // 行世界-游玩项目-发布-列表
      {
        path: "/worldPlayList",
        name: "worldPlayList",
        components: {
          main: () => import("../views/Examine/qcxWorld/Play/list.vue"),
        },
      },
      // 行世界-游玩项目-发布
      {
        path: "/worldPlayListCreate",
        name: "worldPlayListCreate",
        components: {
          main: () => import("../views/Examine/qcxWorld/Play/create.vue"),
        },
      },
      // 行世界-游玩项目-编辑
      {
        path: "/worldPlayListEdit",
        name: "worldPlayListEdit",
        components: {
          main: () => import("../views/Examine/qcxWorld/Play/edit.vue"),
        },
      },
      // 行世界-游玩项目-发布-列表详情
      {
        path: "/worldPlayListDetail",
        name: "worldPlayListDetail",
        components: {
          main: () => import("../views/Examine/qcxWorld/Play/detail.vue"),
        },
      },
      // 行世界-游玩项目-订单管理-列表
      {
        path: "/worldPlayOrderList",
        name: "worldPlayOrderList",
        components: {
          main: () => import("../views/Examine/qcxWorld/Play/Order/index.vue"),
        },
      },
      // 行世界-游玩项目-订单管理-列表-详情
      {
        path: "/worldPlayOrderDetail",
        name: "worldPlayOrderDetail",
        components: {
          main: () => import("../views/Examine/qcxWorld/Play/Order/detail.vue"),
        },
      },
      // 行世界-游玩项目-订单管理-列表-详情
      {
        path: "/worldPlayOrderListDetail",
        name: "worldPlayOrderListDetail",
        components: {
          main: () => import("../views/Examine/qcxWorld/Play/Order/list.vue"),
        },
      },
    ],
  },

  // ---------------------------------------------------------------
  // 区代理后台
  {
    path: "/IndexAgent",
    component: IndexAgent,
    children: [
      // 运营数据
      {
        path: "/TodayData", // 今日数据
        name: "TodayData",
        components: {
          main: () => import("../views/Agent/TodayData/index.vue"),
        },
      },
      {
        path: "/RealName", // 实名认证
        name: "RealName",
        components: {
          main: () => import("../views/Agent/RealName/index.vue"),
        },
      },
      {
        path: "/Owner", // 实名认证
        name: "Owner",
        components: {
          main: () => import("../views/Agent/Owner/index.vue"),
        },
      },
      {
        path: "/PassengerTrip", // 乘客行程
        name: "PassengerTrip",
        components: {
          main: () => import("../views/Agent/PassengerTrip/index.vue"),
        },
      },
      {
        path: "/OwnerTrip", // 车主行程
        name: "OwnerTrip",
        components: {
          main: () => import("../views/Agent/OwnerTrip/index.vue"),
        },
      },
      {
        path: "/ActiveUsers", // 活跃用户
        name: "ActiveUsers",
        components: {
          main: () => import("../views/Agent/ActiveUsers/index.vue"),
        },
      },

      // 财务数据
      {
        path: "/InComeBox", // 趣宝盒收益
        name: "/InComeBox",
        components: {
          main: () => import("../views/Agent/income/box.vue"),
        },
      },
      {
        path: "/InComeNFTHead", // nft头像制作收益
        name: "/InComeNFTHead",
        components: {
          main: () => import("../views/Agent/income/NFTHead.vue"),
        },
      },

      {
        path: "/a", // nft头像制作收益
        name: "/a",
        components: {
          main: () => import("../views/Agent/a/index.vue"),
        },
      },
    ],
  },

  // ---------------------------------------------------------------
  // 国际版
  {
    path: "/IndexInternation",
    component: IndexInternation,
    children: [
      {
        path: "/Internation/RealName", // 实名认证
        name: "InternationRealName",
        components: {
          main: () => import("../views/Internation/RealName/index.vue"),
        },
      },
      {
        path: "/Internation/RealName/examinePass", // 实名认证-审核通过
        name: "InternationRealNamePass",
        components: {
          main: () => import("../views/Internation/RealName/success.vue"),
        },
      },
      {
        path: "/Internation/RealName/examineRefuse", // 实名认证-审核拒绝
        name: "InternationRealNameFail",
        components: {
          main: () => import("../views/Internation/RealName/fail.vue"),
        },
      },

      {
        path: "/Internation/Owner", // 车主认证
        name: "InternationOwner",
        components: {
          main: () => import("../views/Internation/Owner/index.vue"),
        },
      },
      {
        path: "/Internation/Owner/examinePass", // 车主认证-审核通过
        name: "InternationOwnerPass",
        components: {
          main: () => import("../views/Internation/Owner/success.vue"),
        },
      },
      {
        path: "/Internation/Owner/examineRefuse", // 车主认证-审核拒绝
        name: "InternationOwnerFail",
        components: {
          main: () => import("../views/Internation/Owner/fail.vue"),
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  let isLogin = localStorage.getItem("userName");
  if (isLogin) {
    next();
  } else {
    if (to.path === "/") {
      next();
    } else {
      next("/");
    }
  }
});

export default router;
