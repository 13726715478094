<template>
  <div class="index_agent">
    <img src="./assets/agent/qcx-logo.png" class="index_logo" alt="" />
    <el-container>
      <el-aside width="230px">
        <div class="aside_header_logo">
          <img src="./assets/agent/logo_group.png" alt="" />
          <div>
            <i class="iconfont icon-didiandingwei_o"></i>
            <p>{{ TitleCity }}</p>
          </div>
        </div>
        <el-menu :default-active="onRoutes">
          <div v-for="(item, index) in submenuList" :key="index">
            <el-menu-item-group>
              <template slot="title">
                <i :class="item.icon" class="iconfont"></i>
                {{ item.title }}</template
              >
              <div v-for="(i, index) in item.childList" :key="index">
                <el-menu-item @click="goCon(i.path)" :index="i.path"
                  >{{ i.title }}
                </el-menu-item>
              </div>
            </el-menu-item-group>
          </div>
        </el-menu>

        <div class="aside_footer">
          <img
            src="./assets/agent/head.png"
            class="aside_footer_head_img"
            alt=""
          />
          <p>{{ userName }}</p>

          <el-dropdown>
            <img
              src="./assets/agent/dian2.png"
              class="aside_footer_dian_img"
              alt=""
            />
            <el-dropdown-menu slot="dropdown">
              <div @click="outLogin">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </div>
              <div @click="changePass">
                <el-dropdown-item>修改密码</el-dropdown-item>
              </div>
              <div
                @click="changePage"
                v-if="role == '[ROLE_admin, ROLE_normal]'"
              >
                <el-dropdown-item>切换审核后台管理系统</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-dialog title="修改密码" :visible.sync="dialogFormPass">
          <el-form :model="form2">
            <el-form-item label="用户账号" :label-width="formLabelWidth">
              <el-input v-model="form2.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="旧密码" :label-width="formLabelWidth">
              <el-input
                v-model="form2.oldPwd"
                autocomplete="off"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码" :label-width="formLabelWidth">
              <el-input
                v-model="form2.newPwd"
                autocomplete="off"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormPass = false">取 消</el-button>
            <el-button type="primary" @click="changePassDialog"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-aside>

      <el-container class="el-container123" style="padding: 0 20px">
        <router-view name="main" />
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { reqUrlAgent } from "@/api/apiUrl";
import { request } from "@/api/request";
export default {
  name: "Home",
  data() {
    return {
      userName: "",

      submenuList: [
        {
          title: "运营数据",
          icon: "icon-zhexiantu",
          childList: [
            {
              title: "今日数据",
              path: "/TodayData",
            },
            {
              title: "实名认证",
              path: "/RealName",
            },
            {
              title: "车主认证",
              path: "/Owner",
            },
            {
              title: "乘客行程",
              path: "/PassengerTrip",
            },
            {
              title: "车主行程",
              path: "/OwnerTrip",
            },
            // {
            //   title: "活跃用户",
            //   path: "/ActiveUsers",
            // },
          ],
        },
        {
          title: "财务数据",
          icon: "icon-caiwufenxi",
          childList: [
            {
              title: "趣宝盒收益",
              path: "/InComeBox",
            },
            {
              title: "NFT头像制作收益",
              path: "/InComeNFTHead",
            },
            // {
            //   title: "a",
            //   path: "/a",
            // },
            // {
            //   title: "行程促成收益",
            // },
            // {
            //   title: "线路NFT收益",
            // },
            // {
            //   title: "广告收益",
            // },
          ],
        },
      ],

      role: "",
      dialogFormPass: false,
      formLabelWidth: "120px",
      form2: {
        username: localStorage.getItem("userName"),
        oldPwd: "",
        newPwd: "",
      },

      TitleCity: "",
    };
  },

  mounted() {
    this.userName = localStorage.getItem("userName");
    this.role = localStorage.getItem("ROLE");
    this.TitleCity = localStorage.getItem("city")
      ? localStorage.getItem("city")
      : localStorage.getItem("province");
  },

  methods: {
    goCon(conE) {
      if (this.$route.path === conE) {
        return;
      } else {
        this.$router.push(conE);
      }
    },

    outLogin() {
      this.$confirm("您确定要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出登录成功",
          });
          this.$router.push("/");
          localStorage.clear();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    changePage() {
      this.$confirm("您是否要切入“审核后台管理系统”", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "切换成功",
          });
          this.$router.push("/index");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    changePass() {
      this.dialogFormPass = true;
    },

    changePassDialog() {
      const opt = {
        url: reqUrlAgent.modifyPassUrl,
        method: "POST",
        params: JSON.stringify({
          username: this.form2.username,
          oldPwd: this.form2.oldPwd,
          newPwd: this.form2.newPwd,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            console.log(res.data);
            this.$message({
              type: "success",
              message: "修改成功，请使用新密码重新登录",
            });
            const opt = {
              url: reqUrlAgent.outLoginUrl,
              method: "POST",
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                } else if (res.data.code == 0) {
                  this.$router.push("/");
                  localStorage.clear();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },
  },

  computed: {
    // 动态返回对应的路由path
    onRoutes() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "PingFangSC-light", "Hiragino Sans GB", "Microsoft YaHei",
    "WenQuanYi Micro Hei", sans-serif;
}
.index_agent {
  display: flex;
  width: 100%;
  height: 100%;
  background: url(./assets/agent/background2.png) no-repeat;
  background-size: 100% 100vh;
  position: relative;

  .index_logo {
    width: 240px;
    height: 160px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .el-container {
    height: 100vh;

    .el-container123 {
      height: 100%;
      overflow-y: scroll;
    }

    ::v-deep .el-aside {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      background-color: unset !important;
      position: relative;

      .aside_header_logo {
        height: 80px;
        margin-top: 32px;
        text-align: center;

        img {
          width: 100px;
          height: 32px;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
        }
      }

      .el-menu {
        border-right: none;
        background-color: unset;
        height: calc(100% - 190px);
        overflow-y: scroll;

        .el-menu-item-group__title {
          font-size: 18px !important;
          color: #333;
          padding: 18px 0 13px 20px;
        }

        i {
          font-weight: bold;
        }

        .el-menu-item {
          width: 80%;
          margin: 0 auto;
          border-radius: 16px;
          height: 50px;
          line-height: 50px;
          color: #666;

          // .menu_icon_dian {
          // width: 18px;
          // height: 18px;
          // margin-right: 10px;
          // }
        }
        .el-menu-item:hover {
          background: #f5f5f5;
        }

        .el-menu-item.is-active {
          background: #6376ff;
          border-radius: 16px;
          color: #fff;
          font-size: 14px;
          //   .menu_icon_dian {
          //     width: 18px;
          //     height: 18px;
          //     margin-right: 10px;
          //     content: url("./assets/agent/dian.png");
          //   }
        }
      }
    }
  }
}

.el-dropdown-menu {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px !important;
}
</style>
